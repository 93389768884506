import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Sidebar from "../Components/Sidebar";
import { useNavigate } from 'react-router-dom';
import { useTable, useFilters, useGlobalFilter, useSortBy } from 'react-table';
import { Rightslider } from '../Components/rightslider';

export const Transaction = () => {
  const [transactions, setTransactions] = useState([]);
  const [username, setUsername] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    axios.get('https://api.functionparks.com/home', { withCredentials: true })
      .then((response) => {
        if (response.data.loggedIn) {
          setUsername(response.data.user.username)
        } else {
          navigate('/');
        }
      })
      .catch((error) => {
        console.error('Error checking authentication:', error);
        navigate('/');
      });
  }, [navigate]);

  useEffect(() => {
    if (username) {
      const fetchTransactions = async () => {
        try {
          const response = await axios.get('https://api.functionparks.com/api/transactionHistory', {
            params: { username: username }
          });
          const formattedTransactions = response.data.map(transaction => ({
            ...transaction,
            payment_date: formatDate(transaction.payment_date) 
          }));
          setTransactions(formattedTransactions);
        } catch (error) {
          console.error('Error fetching transaction history:', error);
        }
      };
  
      fetchTransactions();
    }
  }, [username]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year}`;
  };

  const columns = React.useMemo(
    () => [
      { Header: 'Booking ID', accessor: 'booking_id' },
      { Header: 'Name', accessor: 'name' },
      { Header: 'Payment Date', accessor: 'payment_date' },
      { Header: 'Total Payment', accessor: 'total_payment' },
      { Header: 'Paid Amount', accessor: 'paid_amount' },
      { Header: 'Balance Amount', accessor: 'balance_amount' },
      { Header: 'Payment Method', accessor: 'payment_method' },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter
  } = useTable(
    { columns, data: transactions,initialState: { sortBy: [{ id: 'payment_date', desc: true }] } },
    useFilters,
    useGlobalFilter,
    useSortBy // Add useSortBy hook for sorting
  );

  const { globalFilter } = state;

  return (
    <div style={{ display: "flex" }}>
      <div>
        <Sidebar />
        <Rightslider/>
      </div>
      <div style={{ width: "100%", marginTop: "15px" }}>
        <Link to="/payment" style={{ textDecoration: "none" }}>
          <div style={{ borderRadius: "5px", width: "100px", height: "30px", backgroundColor: "rgb(90, 4, 65)", color: "white" }}>
            <btn style={{ marginLeft: "27px" }}>Back</btn>
          </div>
        </Link>
        <div>
          <h2>Transaction History</h2>
          <input
            className='searchbarrr'
            value={globalFilter || ''}
            onChange={e => setGlobalFilter(e.target.value)}
            placeholder="Search..."
          />
        </div>
        <div style={{ marginTop: "20px", overflowY: "scroll" }}>
          <table {...getTableProps()} className="table">
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      <span>
                        {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
