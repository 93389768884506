import React from 'react';
import './loading.css';

export const Loading = ({ children }) => {
  return (
    <div className='d-flex flex-row justify-content-center align-items-center w-1oo loadheight'>
        <div className="loader">
      {children}... 
      <span></span>
    </div>
    </div>
    
  );
};





