import React, { useState, useContext ,useEffect} from "react";
import { colourContext } from '../context/useecontext';
import { MdOutlineDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import SideBar from "./Sidebar";
import Navlink from "./Navlink";
import { Rightslider } from "./rightslider";



export const Addonitems = () => {
  const [addonItems, setAddonItems] = useState([""]);
  const { selectedcolour } = useContext(colourContext);
  const [username, setusername] = useState("");
  const navigate = useNavigate();


  useEffect(() => {
    Axios.get("https://api.functionparks.com/home", { withCredentials: true })
      .then((response) => {
        if (response.data.loggedIn) {
          setusername(response.data.user.username);
          fetchSlots(response.data.user.username);
        } else {
          navigate("/");
        }
      })
      .catch((error) => {
        console.error("Error checking authentication:", error);
        navigate("/");
      });
  }, []);

  const fetchSlots = (username) => {
    Axios.get("https://api.functionparks.com/api/getaddonitems", {
      params: { username },
    })
      .then((response) => {
        setAddonItems(response.data);
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          console.log("No slots declared for this user");
        } else {
          console.error("Error fetching slots:", error);
        }
      });
  };

  const handleAddNewItem = () => {
    setAddonItems([...addonItems, ""]);
  };

  const handleItemChange = (index, e) => {
    const newItems = [...addonItems];
    newItems[index] = e.target.value;
    setAddonItems(newItems);
  };

  const handleDeleteItem = (index) => {
    const newItems = addonItems.filter((_, i) => i !== index);
    setAddonItems(newItems);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (addonItems.some(item => item.trim() === "")) {
      alert("All fields are required");
      return;
    }
    e.preventDefault();
    Axios.post("https://api.functionparks.com/api/addonitems", {
      username: username,
      addonItems
    })
      .then((response) => {
        alert(response.data.message);
      })
      .catch((error) => {
        console.error("Error saving slots:", error);
      });
  };

  return (
    <div style={{ display: "flex", width: "100%" }}>
     <div>
        <SideBar />
      </div>
      <div className="w-100 text-center">
      <Navlink />
        <Rightslider />
        <div className="d-flex flex-row justify-content-center align-items-center w-100">
          <div className="w-50 text-center">
            <h2 className="mt-3">Add your items</h2>
            <form onSubmit={handleSubmit}>
              <p>Items</p>
              {addonItems.map((item, index) => (
                <div key={index} style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                  <input
                    type="text"
                    placeholder={`Item ${index + 1} name`}
                    value={item}
                    onChange={(e) => handleItemChange(index, e)}
                    style={{ flex: 1, marginRight: "10px"}}
                  />
                  <button type="button" onClick={() => handleDeleteItem(index)} className="btn btn-danger">
                  <MdOutlineDelete />
                  </button>
                </div>
              ))}
              <button type="button" onClick={handleAddNewItem} style={{ margin: "10px", backgroundColor: selectedcolour }}>+</button>
              <br />
              <button type="submit" style={{ backgroundColor: selectedcolour }}>Save Items</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
