import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './list.css';
import { Link } from 'react-router-dom';
import Sidebar from "../Components/Sidebar";
import { useNavigate } from 'react-router-dom';
import { useTable, useFilters, useGlobalFilter, useSortBy } from 'react-table';
import { Rightslider } from '../Components/rightslider';

export const Upcoming_events = () => {
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [username, setUsername] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    axios.get('https://api.functionparks.com/home', { withCredentials: true })
      .then((response) => {
        if (response.data.loggedIn) {
          setUsername(response.data.user.username)
        } else {
          navigate('/');
        }
      })
      .catch((error) => {
        console.error('Error checking authentication:', error);
        navigate('/');
      });
  }, []);

  useEffect(() => {
    const fetchUpcomingEvents = async () => {
      try {
        const response = await axios.get('https://api.functionparks.com/api/upcomingEvents',{params: { username: username }});
        const formattedEvents = response.data.map(event => ({
          ...event,
          date: formatDate(event.date) // Formatting date here
        }));
        setUpcomingEvents(formattedEvents);
      } catch (error) {
        console.error('Error fetching upcoming events:', error);
      }
    };

    if (username) {
      fetchUpcomingEvents();
    }
  }, [username]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year}`;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Booking ID',
        accessor: 'booking_id',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Phone Number',
        accessor: 'phone_number',
      },
      {
        Header: 'Date',
        accessor: 'date',
      },
      {
        Header: 'Time Slot',
        accessor: 'timeslot',
      },
      {
        Header: 'Purpose of Event',
        accessor: 'purposeOfEvent',
      },
      {
        Header: 'Event Details',
        accessor: 'eventDetails',
      },
    ],
    []
  );

  const data = React.useMemo(() => upcomingEvents, [upcomingEvents]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    { columns, data, initialState: { sortBy: [{ id: 'date', desc: false }] } },    useFilters,
    useGlobalFilter,
    useSortBy // Add useSortBy hook
  );

  const { globalFilter } = state;

  return (
    <div style={{display:"flex"}}>
      <div>
        <Sidebar />
        <Rightslider/>
      </div>
      <div style={{width:"100%"}} >
        <div style={{width:"100%",marginTop:"20px"}}>
          <Link to="/cal" style={{ textDecoration: "none" }}>
            <div style={{borderRadius:"5px",width:"100px",height:"30px",backgroundColor:" rgb(90, 4, 65)",color:"white"}}>
              <btn style={{marginLeft:"27px"}}>Back</btn>
            </div>
          </Link>

          <h2>Upcoming Events</h2>
          <div>
            <input
              className='searchbarrr'
              placeholder="Search..."
              value={globalFilter || ''}
              onChange={e => {
                setGlobalFilter(e.target.value);
              }}
            />
          </div>
          <table {...getTableProps()} className="table">
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      <span>
                        {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Upcoming_events;
