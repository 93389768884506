import React, { useState, useEffect } from "react";
import Axios from 'axios';
import lamp from "../images/lamp.jpg"
import { Link, useNavigate } from "react-router-dom";
import "./Login.css"
export const Login = () => {
    const [email, setemail] = useState('');
    const [phonenumber, setphonenumber] = useState('');
    const [username, setusername] = useState('');
    const [password, setpassword] = useState('');
    const [conformpassword, setconformpassword] = useState('');

    const [loginusername, setloginusername] = useState('');
    const [loginpassword, setloginpassword] = useState('');
    const navigate = useNavigate();
    const [loginstatus, setloginstatus] = useState('');
    const [token, setToken] = useState(null);

    Axios.defaults.withCredentials = true;

    function isEmptyOrSpaces(str) {
        return str === null || str.match(/^ *$/) !== null;
    }

    function Validation() {
        let emailregex = /^[a-zA-Z0-9]+@(gmail|yahoo|outlook)\.com$/;
        let userregex = /^[a-zA-Z0-9]{5,}$/;

        if (isEmptyOrSpaces(username) || isEmptyOrSpaces(email) || isEmptyOrSpaces(phonenumber) ||
            isEmptyOrSpaces(password)) {
            alert("you cannot left any field empty");
            return false;
        }

        if (!emailregex.test(email)) {
            alert("enter a valid email");
            return false;
        }
        if (!userregex.test(username)) {
            alert("-username can only be alphanumeric\n-username must be aleast 5 characters \n-username cannot contain spaces");
            return false;
        }
        if (phonenumber.length < 10) {
            alert("enter a valid Phone number");
            return false;
        }

        return true;
    }

    const handleregistration = (event) => {
        event.preventDefault();
        if (!Validation()) {
            return;
        };

        if (password === conformpassword) {
            Axios.post('https://api.functionparks.com/registration', {
                username: username,
                password: password,
                email: email,
                phonenumber: phonenumber,

            }).then((response) => {
                console.log(response)
                alert('regestration successfull')
            })

        } else {
            alert("You have entered Wrong password")
        }

    };

    const handlelogin = (event) => {
        event.preventDefault();
        const trimmedLoginPassword = loginpassword.trim();
        Axios.post('https://api.functionparks.com/login', {
            username: loginusername,
            password: trimmedLoginPassword
        }, { withCredentials: true }).then((response) => {
            if (response.data.token) {
                setToken(response.data.token);
                const user = response.data.user;
                if (user) {
                    setloginstatus(`Welcome ${user.username}`);
                    navigate("/Home");
                } else {
                    setloginstatus("User data not available in the token.");
                }
            } else {
                setloginstatus(response.data.message);
            }
        });
    };

    useEffect(() => {
        if (token) {
            Axios.get('https://api.functionparks.com/login', {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then((response) => {
                if (response.data.loggedIn === true) {
                    setloginstatus(`Welcome, ${response.data.user.name}`);
                }
            });
        }
    }, [token]);

    return (
        <div className="loginbgg d-flex flex-row justify-content-center align-items-center text-center" style={{height:"100vh"}}>
                      
                
                 <form style={{height:"55vh"}} className="logingform">
                    <label  style={{fontSize:"30px",fontWeight:"600",marginTop:"30px"}} htmlFor="chk" aria-hidden="true">Login</label>
                    <input style={{borderRadius:"5px"}} placeholder="username" type="text" onChange={(e) => setloginusername(e.target.value)} />
                    <input style={{borderRadius:"5px"}} placeholder="password" type="password" onChange={(e) => setloginpassword(e.target.value)} />
                    <p className="loginstatus">{loginstatus}</p>
                    <Link to='/forgetpassword' ><b className="ForgetPassword" >ForgetPassword</b></Link>
                    <br/>

                    <button class="login-button mt-3" onClick={handlelogin}>submit</button>
                </form>
               
                    
        </div>
    );
};
