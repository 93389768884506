import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import './Pages.css';
import { Link, Routes, Route, useNavigate } from 'react-router-dom';
import Gallery from './Gallery';
import Aboutus from './Aboutus';
import Contactus from './Contactus';
import Sidebar from "./Sidebar"
import Navlink from './Navlink';
import { colourContext } from '../context/useecontext';
import { Rightslider } from './rightslider';


const Pages = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const { selectedcolour } = useContext(colourContext);

  useEffect(() => {
    Axios.get('https://api.functionparks.com/home', { withCredentials: true })
      .then((response) => {
        if (response.data.loggedIn) {
          setIsLoggedIn(true);
        } else {
          navigate('/');
        }
      })
      .catch((error) => {
        console.error('Error checking authentication:', error);
        navigate('/');
      });
  }, [navigate]);



  return (
    <div style={{ display: "flex" }}>
      <div>
        <Sidebar />
      </div>
      <div>
      <Navlink/>
      <Rightslider/>
      <div className='three'>
        <div style={{ width: "90vw", marginLeft: "50px" }}>
          <h2>Pages</h2>
          <Link to="/Gallery">
            <button style={{ marginRight: '10px', marginLeft: '10px', background: 'rgb(146, 61, 122)', backgroundColor: selectedcolour }}>Gallery</button>
          </Link>
          <Link to="/Aboutus">
            <button style={{ marginRight: '10px', background: 'rgb(146, 61, 122)', backgroundColor: selectedcolour }}>Aboutus</button>
          </Link>
          <Link to="/Contactus">
            <button style={{ marginRight: '10px', background: 'rgb(146, 61, 122)', backgroundColor: selectedcolour }}>Contactus</button>
          </Link>
         
          
          <Routes>
            <Route path="/Gallery" element={<Gallery />} />
            <Route path="/Aboutus" element={<Aboutus />} />
            <Route path="/Contactus" element={<Contactus />} />
          </Routes>
        </div>
      </div>
      </div>
     
    </div>
  );
};

export default Pages;
