import React, { useState, useEffect } from "react";
import Axios from 'axios';
import lamp from "../images/lamp.jpg"
import { Link, useNavigate } from "react-router-dom";
import "./signup.css";

export const Signup = () => {
    const [email, setemail] = useState('');
    const [phonenumber, setphonenumber] = useState('');
    const [username, setusername] = useState('');
    const [password, setpassword] = useState('');
    const [conformpassword, setconformpassword] = useState('');

    const [loginstatus, setloginstatus] = useState('');
    const [token, setToken] = useState(null);

    Axios.defaults.withCredentials = true;

    function isEmptyOrSpaces(str) {
        return str === null || str.match(/^ *$/) !== null;
    }

    function Validation() {
        let emailregex = /^[a-zA-Z0-9]+@(gmail|yahoo|outlook)\.com$/;
        let userregex = /^[a-zA-Z0-9]{5,}$/;

        if (isEmptyOrSpaces(username) || isEmptyOrSpaces(email) || isEmptyOrSpaces(phonenumber) ||
            isEmptyOrSpaces(password)) {
            alert("you cannot left any field empty");
            return false;
        }

        if (!emailregex.test(email)) {
            alert("enter a valid email");
            return false;
        }
        if (!userregex.test(username)) {
            alert("-username can only be alphanumeric\n-username must be aleast 5 characters \n-username cannot contain spaces");
            return false;
        }
        if (phonenumber.length < 10) {
            alert("enter a valid Phone number");
            return false;
        }

        return true;
    }

    const handleregistration = (event) => {
        event.preventDefault();
        if (!Validation()) {
            return;
        };

        if (password === conformpassword) {
            Axios.post('https://api.functionparks.com/registration', {
                username: username,
                password: password,
                email: email,
                phonenumber: phonenumber,
            }).then((response) => {
                alert('Registration successful')
            }).catch((error) => {
                if (error.response && error.response.status === 400) {
                    alert('Username or email already exists');
                } else {
                    console.error('Error:', error);
                    alert('An error occurred during registration');
                }
            });
            

        } else {
            alert("You have entered Wrong password")
        }

    };



    useEffect(() => {
        if (token) {
            Axios.get('https://api.functionparks.com/login', {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then((response) => {
                if (response.data.loggedIn === true) {
                    setloginstatus(`Welcome, ${response.data.user.name}`);
                }
            });
        }
    }, [token]);

    return (
        <div className="signup-container">
            <div className="signup-form">
                <div style={{ position: "absolute", marginTop: "-40px" }}>
                    <img src={lamp} style={{ width: "200%", height: "600px" }} />
                </div>
                <div className="login" style={{ position: "relative" }}>
                    <form className="w-100">
                        <div className="d-flex flex-row justify-content-center align-items-center">
                            <div className="sigupbox">
                                <label htmlFor="chk" aria-hidden="true">Sign up</label>
                                <input placeholder="username" type="text" required onChange={(e) => setusername(e.target.value)} />
                                <input placeholder="Email" type="text" onChange={(e) => setemail(e.target.value)} />
                                <input placeholder="Phone Number" type="text" onChange={(e) => setphonenumber(e.target.value)} />
                                <input placeholder="password" type="password" onChange={(e) => setpassword(e.target.value)} />
                                <input placeholder="Confirm Password" type="password" onChange={(e) => setconformpassword(e.target.value)} />
                                <button onClick={handleregistration}>submit</button>
                                <h1 className="loginstatus">{loginstatus}</h1>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
    
};
