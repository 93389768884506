import React, { useState, useEffect } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import Axios from 'axios';

import {  Badge } from 'antd';
import './MyBookings.css';

import Sidebar from "./Sidebar"
const MyBookings = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    Axios.get('https://api.functionparks.com/home', { withCredentials: true })
      .then((response) => {
        if (response.data.loggedIn) {
          setIsLoggedIn(true);
        } else {
          navigate('/');
        }
      })
      .catch((error) => {
        console.error('Error checking authentication:', error);
        navigate('/');
      });
  }, [navigate]);
  
  const [bookings, setBookings] = useState([
    { id: 1, name: 'Birthday Party', status: 'Completed' },
    { id: 2, name: 'Wedding Ceremony', status: 'Canceled' },
    { id: 3, name: 'Corporate Event', status: 'Completed' },
    { id: 4, name: 'Birthday Party', status: 'Completed' },
    { id: 5, name: 'Wedding Ceremony', status: 'Canceled' },
    { id: 6, name: 'Corporate Event', status: 'Completed' },
    { id: 7, name: 'Birthday Party', status: 'Completed' },
    { id: 8, name: 'Wedding Ceremony', status: 'Canceled' },
    { id: 9, name: 'Corporate Event', status: 'Completed' },
    { id: 10, name: 'Future Event', status: 'Upcoming' },
  ]);

  const [upcomingBookings] = useState([
    { id: 10, name: 'Future Event', status: 'Upcoming' },
    { id: 11, name: 'Future Event', status: 'Upcoming' },
  ]);

  const [currentView, setCurrentView] = useState('all'); // 'all', 'canceled', 'completed', 'upcoming', 'calendar', 'list'

  const events = [
    {
      date: '2024-02-12',
      description: 'Event',
    },
  ];

  const handleCancelBooking = (bookingId) => {
    const updatedBookings = bookings.map((booking) =>
      booking.id === bookingId ? { ...booking, status: 'Canceled' } : booking
    );
    setBookings(updatedBookings);
  };

  const handleEditBooking = (bookingId) => {
    console.log(`Edit booking with ID: ${bookingId}`);
  };

  const handleDeleteBooking = (bookingId) => {
    const updatedBookings = bookings.filter((booking) => booking.id !== bookingId);
    setBookings(updatedBookings);
  };

  const filteredBookings = () => {
    switch (currentView) {
      case 'canceled':
        return bookings.filter((booking) => booking.status === 'Canceled');
      case 'completed':
        return bookings.filter((booking) => booking.status === 'Completed');
      case 'upcoming':
        return upcomingBookings;
      case 'calendar':
        return [];
      case 'list':
        return bookings;
      default:
        return bookings;
    }
  };

  const dateCellRender = (value) => {
    const date = value.format('YYYY-MM-DD');
    const dayEvents = events.filter((event) => event.date === date);

    return (
      <div>
        {dayEvents.map((event, index) => (
          <Badge key={index} status="success" text={event.description} />
        ))}
      </div>
    );
  };

  const handleBack = () => {
    switch (currentView) {
      case 'list':
        setCurrentView('calendar');
        break;
      case 'calendar':
        setCurrentView('all');
        break;
      case 'canceled':
      case 'completed':
      case 'upcoming':
        setCurrentView('list');
        break;
      default:
        break;
    }
  };

  return (
    <div style={{display:"flex"}}>
      <div>
<Sidebar/>
      </div>
    
    <div className='one'>

      <br />
      {currentView !== 'calendar' && (
        <div className='two'>
          {/* {currentView !== 'list' && (
            <button onClick={() => setCurrentView('calendar')} style={{ marginRight: '8px' }}>
              book now
            </button>
           
          )} */}
          {/* <Link to="/Addbookings">
          <button style={{ marginRight: '8px' }}>
            +ADD
          </button>
          </Link> */}
        </div>
      )}
        <Link to="/cal">
          <button className='ck' >
            calender
          </button>
          </Link>
      <br />
      {currentView !== 'calendar' && (
        <div className='three'>
          {/* <button onClick={() => setCurrentView('all')} style={{ marginRight: '8px' }}>
            All Bookings
          </button> */}
          <button onClick={() => setCurrentView('canceled')} className='can'>
            Canceled Bookings
          </button>
          <button onClick={() => setCurrentView('completed')}className='com' >
            Completed Bookings
          </button>
          <button onClick={() => setCurrentView('upcoming')} className='upc' >
            Upcoming Bookings
          </button>
        </div>
      )}
      <br />
      {/* {currentView === 'calendar' ? (
        <>
        <button onClick={() => setCurrentView('list')} style={{ marginRight: '8px' }}>
            List View
          </button>
          <h2>Calendar</h2>
          <button onClick={handleBack} style={{ marginBottom: '8px' }}>
            Back
          </button>
          <Calendar dateCellRender={dateCellRender} />
        </>
      ) : (
         */}
        <div className='Eight'>
          {/* <Link to="/cal">
          <button className='ck' >
            Back
          </button>
          </Link> */}
          
          <h2 className='fc'>Function Hall Bookings</h2>
          <div className='table1'>
            <table>
              <thead>
                <tr>
                  <th>Booking ID</th>
                  <th>Function Name</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredBookings().map((booking) => (
                  <tr key={booking.id}>
                    <td>{booking.id}</td>
                    <td>{booking.name}</td>
                    <td>{booking.status}</td>
                    <td>
                      {booking.status !== 'Canceled' && (
                        <div className='bbn'>
                          <button onClick={() => handleEditBooking(booking.id)}  >
                            Edit
                          </button>
                          <button onClick={() => handleDeleteBooking(booking.id)} >
                            Delete
                          </button>
                          <button onClick={() => handleCancelBooking(booking.id)}>Cancel</button>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>  
        </div>
      
    </div>
    </div>
  );
};

export default MyBookings;



