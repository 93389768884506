import React, { useEffect, useState ,useContext } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import SideBar from "./Sidebar";
import Navlink from "./Navlink";
import { Rightslider } from "./rightslider";
import {colourContext} from '../context/useecontext'


export const SLots = () => {
  const [username, setusername] = useState("");
  const [numSlots, setNumSlots] = useState(0);
  const [slotNames, setSlotNames] = useState([]);
  const navigate = useNavigate();
  const {selectedcolour} = useContext(colourContext)


  useEffect(() => {
    Axios.get("https://api.functionparks.com/home", { withCredentials: true })
      .then((response) => {
        if (response.data.loggedIn) {
          setusername(response.data.user.username);
          fetchSlots(response.data.user.username);
        } else {
          navigate("/");
        }
      })
      .catch((error) => {
        console.error("Error checking authentication:", error);
        navigate("/");
      });
  }, []);

  const fetchSlots = (username) => {
    Axios.get("https://api.functionparks.com/api/getSlots", {
      params: { username },
    })
      .then((response) => {
        setSlotNames(response.data);
        setNumSlots(response.data.length);
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          console.log("No slots declared for this user");
        } else {
          console.error("Error fetching slots:", error);
        }
      });
  };

  const handleNumSlotsChange = (e) => {
    const number = parseInt(e.target.value, 10) || 0;
    if (number >= 0) {
      setNumSlots(number);
      setSlotNames(new Array(number).fill(""));
    }
  };

  const handleSlotNameChange = (index, e) => {
    const newSlotNames = [...slotNames];
    newSlotNames[index] = e.target.value;
    setSlotNames(newSlotNames);
  };

  const handleSubmit = (e) => {
    if (slotNames.length === 0) {
      alert("All Fields Required");
      return;
    }
    e.preventDefault();
    Axios.post("https://api.functionparks.com/api/saveSlots", {
      username: username,
      slots: slotNames,
    })
      .then((response) => {
        alert(response.data.message);
      })
      .catch((error) => {
        console.error("Error saving slots:", error);
      });
  };

  return (
    <div style={{ display: "flex", width: "100%" }}>
      <div>
        <SideBar />
      </div>
      <div className="w-100 text-center">
        <Navlink />
        <Rightslider />
        <div className="d-flex flex-row justify-content-center align-items-center w-100">
          <div className="w-50 text-center">
            <h2 className="mt-3">Declare your slots</h2>
            <form onSubmit={handleSubmit}>
              <p>Number of slots per day</p>
              <input
                type="number"
                value={numSlots}
                onChange={handleNumSlotsChange}
                min="0"
              />
              <p>Slots names</p>
              {Array.from({ length: numSlots }).map((_, index) => (
                <div key={index}>
                  <input
                    type="text"
                    placeholder={`Slot ${index + 1} name`}
                    value={slotNames[index] || ""}
                    onChange={(e) => handleSlotNameChange(index, e)}
                  />
                </div>
              ))}
              <br />
              <button type="submit" style={{backgroundColor: selectedcolour}}>Save Slots</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
