import React, { useState, useEffect,useContext } from 'react';
import "./Addbookings.css";
import { Link, useNavigate } from "react-router-dom";
import flw from "../images/flw.jpg";
import Sidebar from "./Sidebar";
import axios from 'axios';
import { Loading } from './loading';
import Axios from 'axios';
import Navlink from './Navlink';
import "./payment.css";
import {colourContext} from '../context/useecontext'
import { Rightslider } from './rightslider';



const Addbookings = () => {

  
  const [bookingId, setBookingId] = useState('');
  const [name, setName] = useState('');
  const [totalPayment, setTotalPayment] = useState('');
  const [paidAmount, setPaidAmount] = useState('');
  const [balanceamount, setbalanceamount] = useState('');
  const [paidMethod, setPaidMethod] = useState('');
  const [paymentHistoryExists, setPaymentHistoryExists] = useState(false);
  const [historypayamount, sethistorypayamount] = useState('');
  const [rangeValue, setRangeValue] = useState(0); // New state for range value
  const [loadingpage, setLoadingpage] = useState(false); 
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setusername] = useState('');
  const navigate = useNavigate();
  const {selectedcolour} = useContext(colourContext)

  

  useEffect(() => {
    Axios.get('https://api.functionparks.com/home', { withCredentials: true })
      .then((response) => {
        if (response.data.loggedIn) {
          setIsLoggedIn(true);
          setusername(response.data.user.username)
        } else {
          navigate('/');
        }
      })
      .catch((error) => {
        console.error('Error checking authentication:', error);
        navigate('/');
      });
  }, [navigate]);


  const fetchData = async () => {
    try {
      const paymentResponse = await axios.get(`https://api.functionparks.com/api/payments/${bookingId}/${username}`);
      if (paymentResponse.data.exists) {
        const paymentDetails = paymentResponse.data.paymentDetails;
        if (paymentDetails) {
          setPaymentHistoryExists(true);
          setTotalPayment(paymentDetails.total_payment);
          setPaidAmount(paymentDetails.paid_amount);
          setbalanceamount(paymentDetails.balance_amount);
          setName(paymentDetails.name);
        } else {
          setPaymentHistoryExists(false);
          setTotalPayment('');
          setPaidAmount('');
          setbalanceamount('');
          setName('');
        }
      } else {
        const nameResponse = await axios.get(`https://api.functionparks.com/api/getNameFromBookingId/${bookingId}/${username}`);
        setName(nameResponse.data.name);
        setPaymentHistoryExists(false);
        setTotalPayment('');
        setPaidAmount('');
        setbalanceamount('');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setPaymentHistoryExists(false);
      setTotalPayment('');
      setPaidAmount('');
      setbalanceamount('');
      setName('');
    }
  };

  useEffect(() => {
    if (bookingId && username) {
      fetchData();
    }
  }, [bookingId, username]); // Trigger fetchData when bookingId or username changes

  const handleBookingIdChange = (event) => {
    setBookingId(event.target.value);
  };



  const handleSubmit = async () => {
    
    const updatedbalance = balanceamount - historypayamount;
    const newpaymentbalance = totalPayment - paidAmount;
  
    // Check if any of the required fields are empty
    if (!bookingId || !name || !totalPayment || !paidAmount || !paidMethod) {
      console.log('Fields that are empty:', {
        bookingId,
        name,
        totalPayment,
        paidAmount,
        paidMethod,
      });

      alert('Please fill in all the required fields.');
      setLoadingpage(false);
      return;
    }

    if(paymentHistoryExists){
      if (!historypayamount){
        alert('Please fill in all the required fields.');
        return;
      }
    }

    setLoadingpage(true);
  
    try {
      const currentDate = new Date().toISOString().slice(0, 10);
      const response = await axios.get(`https://api.functionparks.com/api/payments/${bookingId}/${username}`);
      if (response.data.exists) {
        await axios.put(`https://api.functionparks.com/api/updatePayment/${bookingId}`, {
          username,
          updatedbalance,
          totalPayment,
          historypayamount,
          paidDate: currentDate, 
          paidMethod
        });
        alert('Payment details updated successfully');
      } else {
        await axios.post('https://api.functionparks.com/api/payments', {
          username,
          bookingId,
          totalPayment,
          paidAmount,
          balanceamount: newpaymentbalance,
          paidDate: currentDate,
          paidMethod
        });
        alert('Payment Details added successfully');
      }
  
      // Clear the input fields after successful submission
      setBookingId('');
      setName('');
      setTotalPayment('');
      setPaidAmount('');
      setbalanceamount('');
      setPaidMethod('');
      sethistorypayamount('');
      setLoadingpage(false);
      setRangeValue(0);
    } catch (error) {
      console.error('Error submitting payment:', error);
      alert('Error submitting payment. Please try again.');
      setLoadingpage(false);
    }
  };
  
  useEffect(() => {
    if (totalPayment && paidAmount) {
      const percentage = paidAmount !== 0 ? (paidAmount / totalPayment) * 100 : 0;
      setRangeValue(Math.round(percentage)); // Round the percentage to the nearest whole number
    } else {
      setRangeValue(0);
    }
  }, [totalPayment, paidAmount]);

  useEffect(() => {
    if (paymentHistoryExists) {
      const percentage = balanceamount !== 0 ? ((totalPayment - balanceamount) / totalPayment) * 100 : 0;
      setRangeValue(Math.round(percentage));
    } else {
      setRangeValue(0);
    }
  }, [paymentHistoryExists, balanceamount, totalPayment]);

  const balamout = totalPayment - paidAmount


  function convertToIndianNumberingSystem(number) {
    // Define the Indian number words mapping
    const indianNumberWords = {
        1: 'One',
        2: 'Two',
        3: 'Three',
        4: 'Four',
        5: 'Five',
        6: 'Six',
        7: 'Seven',
        8: 'Eight',
        9: 'Nine',
        10: 'Ten',
        11: 'Eleven',
        12: 'Twelve',
        13: 'Thirteen',
        14: 'Fourteen',
        15: 'Fifteen',
        16: 'Sixteen',
        17: 'Seventeen',
        18: 'Eighteen',
        19: 'Nineteen',
        20: 'Twenty',
        30: 'Thirty',
        40: 'Forty',
        50: 'Fifty',
        60: 'Sixty',
        70: 'Seventy',
        80: 'Eighty',
        90: 'Ninety',
        100: 'Hundred',
        1000: 'Thousand',
        100000: 'Lakh',
        10000000: 'Crore'
    };

    let result = '';

    // Function to convert a number less than 1000 to words
    function convertLessThanOneThousand(number) {
        let word = '';
        if (number >= 100) {
            word += indianNumberWords[Math.floor(number / 100)] + ' ' + indianNumberWords[100] + ' ';
            number %= 100;
        }
        if (number > 0) {
            if (word !== '') word += 'and ';
            if (number <= 20) {
                word += indianNumberWords[number];
            } else {
                word += indianNumberWords[Math.floor(number / 10) * 10] + ' ';
                if ((number % 10) !== 0) {
                    word += indianNumberWords[number % 10];
                }
            }
        }
        return word;
    }

    if (number === 0) {
        return 'Zero';
    }

    // Convert billions, millions, thousands
    if (number >= 10000000) {
        result += convertLessThanOneThousand(Math.floor(number / 10000000)) + ' ' + indianNumberWords[10000000] + ' ';
        number %= 10000000;
    }
    if (number >= 100000) {
        result += convertLessThanOneThousand(Math.floor(number / 100000)) + ' ' + indianNumberWords[100000] + ' ';
        number %= 100000;
    }
    if (number >= 1000) {
        result += convertLessThanOneThousand(Math.floor(number / 1000)) + ' ' + indianNumberWords[1000] + ' ';
        number %= 1000;
    }

    
    result += convertLessThanOneThousand(number);

    return result.trim() + ' Rupees';
  }



  return (
    <div style={{ display: "flex" }}>
      <div>
        <Sidebar />
        <Rightslider/>
      </div>
      {loadingpage ? (
        <Loading>Payment Confirming</Loading>
      ) : (
      <div className="bsa">
          <Navlink/>
        <div>
          <img src={flw} className="fl" />

        </div>

        <div className="paymargingg">
          <div className=" d-flex justify-content-evenly cc">
            <div className="col-sm-5 form-border">

            <Link to='/transaction'>
            <button className='btn btn-primary' style={{ marginTop: "10px", backgroundColor: selectedcolour }}>Transaction History</button>
          </Link>

            {bookingId && (
              <div className='text-center mt-3'>
                <input type='range' value={rangeValue} readOnly className='w-100' style={{ background: 'red', color: 'green' }} />
                <p>{rangeValue}% of Amount Paid</p>
              </div>
            )}

            <br/>

            <label  htmlFor="bookingId">Enter Booking ID</label>
                <input
                   required
                  type="text"
                  id='bookingId'
                  placeholder='Enter Booking ID'
                  value={bookingId}
                  onChange={handleBookingIdChange}
            />
          

              <label  htmlFor="name">Name</label>
                <input
                  type='text'
                  id="name"
                  placeholder='Name'
                  value={name}
                  readOnly
                />

            

          {paymentHistoryExists && (
                      <>
                      
                        <label htmlFor="totalPayment">Total Payment</label>
                        <input
                          type='text'
                          id="totalPayment"
                          placeholder='Total Payment'
                          value={totalPayment}
                          readOnly
                        />
                        <p>{isNaN(parseFloat(totalPayment)) ? '' : convertToIndianNumberingSystem(parseFloat(totalPayment))}</p>

                        <label htmlFor="balanceAmount">Balance Amount</label>
                        <input
                          type='text'
                          id="balanceAmount"
                          placeholder='Balance Amount'
                          value={balanceamount}
                          readOnly
                        
                        />
                        <p>{isNaN(parseFloat(balanceamount)) ? '' : convertToIndianNumberingSystem(parseFloat(balanceamount))}</p>

                        <label htmlFor="payAmount">Pay Amount</label>
                        <input
                          required
                          type='text'
                          id="payAmount"
                          placeholder='Pay Amount'
                          className='payamt'
                          onChange={(e) => {
                            const enteredAmount = parseFloat(e.target.value);
                            if (enteredAmount > balanceamount) {
                              alert('Payment amount cannot exceed Balance Amount.');
                              e.target.value = '0'; 
                            }
                            sethistorypayamount(e.target.value); // Update historypayamount state regardless
                          }}
                          value={historypayamount} // Ensure controlled input
                        />
                        <p>{isNaN(parseFloat(historypayamount)) ? '' : convertToIndianNumberingSystem(parseFloat(historypayamount))}</p>
                      </>
                    )}

          {!paymentHistoryExists && (
            <>
              <label htmlFor="totalPayment">Total Payment</label>
              <input
                required
                type='number'
                id="totalPayment"
                placeholder='Total Payment'
                value={totalPayment}
                onChange={(e) => setTotalPayment(e.target.value)}
              />
              
              <p>{isNaN(parseFloat(totalPayment)) ? '' : convertToIndianNumberingSystem(parseFloat(totalPayment))}</p>

              <label htmlFor="payAmount">Pay Amount</label>
              <input
                required
                type='number'
                id="payAmount"
                placeholder='Pay Amount'
                className='payamt'
                onChange={(e) => {
                  const enteredAmount = parseFloat(e.target.value);
                  if (enteredAmount > totalPayment) {
                    alert('Payment amount cannot exceed total payment.');
                    e.target.value = 0; // Reset to 0 if the entered amount is invalid
                  } else {
                    setPaidAmount(enteredAmount); // Update paidAmount state otherwise
                  }
                }}
              />
              <p>{isNaN(parseFloat(paidAmount)) ? '' : convertToIndianNumberingSystem(parseFloat(paidAmount))}</p>

              <label htmlFor="balanceAmount">Balance Amount</label>
              <input
                
                type='text'
                id="balanceAmount"
                placeholder='Balance Amount'
                value={balamout}
                readOnly
              />
                {balamout !== 0 && (
                    <p>{isNaN(parseFloat(balamout)) ? '' : convertToIndianNumberingSystem(parseFloat(balamout))}</p>
                )}
            </>
          )}

            <div>
            <br/>
            <label>Payment Method</label>
                <select className="form-select" id="dropdown" required onChange={(e) => setPaidMethod(e.target.value)}>
                  <option defaultValue>Select an option</option>
                  <option value="Cash">Cash</option>
                  <option value="Phonepay">Phonepay</option>
                  <option value="Googlepay"> Googlepay</option>
                  <option value="Netbanking">Netbanking</option>
                </select>
            </div>
              
              <div className="mb-3 row">
                <div className="button-container">
                    <button
                      type="reset"
                      className="btn btn-sm btn-secondary me-2"
                      onClick={handleSubmit}
                      style={{ backgroundColor: selectedcolour,borderStyle:"none" }}
                    >
                      Submit
                    </button>
                </div>
              </div>       

            
            </div>
          </div>
        </div>
      </div>
      )}

    </div>
  );
};

export default Addbookings;
