import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Axios from 'axios';
import { FcBusinessman } from "react-icons/fc";
import './Home.css';
import './Navlink.css';
import {colourContext} from '../context/useecontext'


const Navlink = () => {
  const [logoutStatus, setLogoutStatus] = useState('');
  const [themeColor, setThemeColor] = useState('#0080FE');
  const navigate = useNavigate();
  const [username, setusername] = useState('');
  const {colourselect,functionhallname,functionhall} = useContext(colourContext)


  useEffect(() => {
    Axios.get('https://api.functionparks.com/home', { withCredentials: true })
      .then((response) => {
        if (response.data.loggedIn) {
          setusername(response.data.user.username);
        } else {
          navigate('/');
        }
      })
      .catch((error) => {
        console.error('Error checking authentication:', error);
        navigate('/');
      });
  }, []);

  const fetchColor = (username) => {
    const storedColor = localStorage.getItem(`${username}_themeColor`);
  
    if (storedColor) {
      setThemeColor(storedColor);
      colourselect(storedColor)
    } else {
      // Make API call to fetch color for the user
      Axios.get(`https://api.functionparks.com/fetch-color?username=${username}`)
        .then((response) => {
          const color = response.data.colour;
          if (color) {
            setThemeColor(color);
            console.log('Color fetched and applied:', color);
            colourselect(storedColor)
            // Store color in local storage for future use
            localStorage.setItem(`${username}_themeColor`, color);
          } else {
            console.error('No color found for the user');
          }
        })
        .catch((error) => {
          console.error('Error fetching color:', error);
        });
    }
  };
  

  useEffect(() => {
    if (username) {
      Axios.get('https://api.functionparks.com/api/profile', { params: { username: username } })
        .then((response) => {
          const userData = response.data.user;
          functionhall(userData.functionhallName);
        })
        .catch((error) => {
          console.error('Error fetching user data:', error);
        });
    }
  }, [username]);

  useEffect(() =>{
    if(username){
        fetchColor(username);
    }
  },[username])

  const handleLogout = () => {
    Axios.get('https://api.functionparks.com/logout', { withCredentials: true }).then((response) => {
      if (response.data.loggedOut) {
        setLogoutStatus('Logged out successfully');
        navigate('/');
      } else {
        setLogoutStatus('Logout failed');
      }
    });
  };



  // Define array of solid colors
  const solidColors = ['#0080FE', '#FE6196', '#F33535', '#333366', '#404040', '#3CB371'];

  // Handle theme color change
  const changeThemeColor = (color) => {
    setThemeColor(color);
    colourselect(color)
    localStorage.setItem(`${username}_themeColor`, color);
    // Make API call to update selected color in the backend
    Axios.post('https://api.functionparks.com/update-color', { username: username, colour: color })
      .then((response) => {
        if (response.data.success) {
          console.log('Color updated successfully in the backend');
        } else {
          console.error('Failed to update color in the backend');
        }
      })
      .catch((error) => {
        console.error('Error updating color in the backend:', error);
      });
  };

  return (
    <div className='mt-3'>
    <h4 className='d-block d-md-none '>{functionhallname}</h4>
      <div className='d-flex flex-row justify-content-around '>
      
      <div>
        <Link to="/bookings" style={{ textDecoration: "none" }}>
          <div className="buttonhome" style={{ backgroundColor: themeColor }}>
            Book Now
          </div>
        </Link>
        <br/>
        <h4 className='d-none d-md-block'>{functionhallname}</h4>
      </div>


        <div className='text-center d-none d-md-block themeboxx'>
        <b className='themeee'>Change Skin</b>
        <div className="colourselector d-flex " >
          {solidColors.map((color, index) => (
            <div >
              <div
                key={index}
                className="themeSquare"
                style={{ backgroundColor: color, width: "30px", height: "30px" }}
                onClick={() => changeThemeColor(color)}
              />
            </div>
           
          ))}
        </div>
        </div>


        <div style={{marginTop:"5px"}}>
        <div >
          <div onClick={handleLogout} style={{ backgroundColor: themeColor,marginLeft:"25px"}} className="buttonhome">
            Logout
          </div>
        </div>
       

        <div>
          <Link to="/profile">
            <div className='profileimg pt-3'>
            <p className='usernamee '> <FcBusinessman className='profileee'/> Welcome <b>{username}</b></p>
            </div>
          </Link>
        </div>
      </div>
      </div>


      <div className='text-center d-block d-md-none '>
        <b className='themeee'>Change Skin</b>
        <div className="colourselector d-flex justify-content-center" >
          {solidColors.map((color, index) => (
            <div >
              <div
                key={index}
                className="themeSquare"
                style={{ backgroundColor: color, width: "30px", height: "30px" }}
                onClick={() => changeThemeColor(color)}
              />
            </div>
           
          ))}
        </div>
        </div>
    </div>
  );
};

export default Navlink;
