import Header from './Header';
import Footer from './Footer';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Axios from 'axios';
 
const Aboutus = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    Axios.get('https://api.functionparks.com/home', { withCredentials: true })
      .then((response) => {
        if (response.data.loggedIn) {
          setIsLoggedIn(true);
        } else {
          navigate('/');
        }
      })
      .catch((error) => {
        console.error('Error checking authentication:', error);
        navigate('/');
      });
  }, [navigate]);

  return (
    <div>
      <Link to="/Pages">
        <button>Back</button>
      </Link>
      <Header />
      
      <div className="content">
        <h1>About Us</h1>
        <p>
          Welcome to our function hall booking platform! Lorem ipsum dolor sit amet,
          consectetur adipiscing elit. Nulla facilisi. Sed ac ultricies libero.
        </p>
        {/* Add more content as needed */}
      </div>
      <Footer />
    </div>
  );
};

export default Aboutus;

