import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@material-ui/core';
import "./Home.css";
import Navlink from './Navlink';
import Sidebar from "./Sidebar";
import BarChart from "./BarChart";
import Pie from "./Pie";
import { colourContext } from '../context/useecontext';
import { Rightslider } from './rightslider';

export const Home = () => {
  const [logoutStatus, setLogoutStatus] = useState('');
  const [themeColor, setThemeColor] = useState('#421F22');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setusername] = useState('');
  const [userData, setUserData] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();
  const { selectedcolour, functionhall } = useContext(colourContext);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [functionhallName, setFunctionhallName] = useState('');

  const [counts, setCounts] = useState({
    ongoingCount: 0,
    bookedCount: 0,
    doneCount: 0
  });

  useEffect(() => {
    Axios.get('https://api.functionparks.com/home', { withCredentials: true })
      .then((response) => {
        if (response.data.loggedIn) {
          setusername(response.data.user.username);
          setIsLoggedIn(true);
        } else {
          navigate('/');
        }
      })
      .catch((error) => {
        console.error('Error checking authentication:', error);
        navigate('/');
      });
  }, []);

  useEffect(() => {
    if (username) {
      Axios.get(`https://api.functionparks.com/api/events/count/${username}`)
        .then((response) => {
          if (response.data.success) {
            setCounts({
              ongoingCount: response.data.ongoingCount,
              bookedCount: response.data.bookedCount,
              doneCount: response.data.doneCount
            });
          } else {
            console.error('Error fetching event counts:', response.data.error);
          }
        })
        .catch((error) => {
          console.error('Error fetching event counts:', error);
        });
    }
  }, [username]);

  useEffect(() => {
    if (username) {
      Axios.get('https://api.functionparks.com/api/profile', { params: { username: username } })
        .then((response) => {
          console.log("response in home", response)
          if (response.data.success && Object.keys(response.data.user).length > 0) {
            setDialogOpen(false);
          } else {
            setDialogOpen(true); 
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            setDialogOpen(true); 
          } else {
            console.error('Error fetching user data:', error);
          }
        });
    }
  }, [username]);
  
  

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleProfileSubmit = () => {
    functionhall(functionhallName);
    Axios.post('https://api.functionparks.com/api/profile', {
      username,
      firstname: firstName,
      lastname: lastName,
      functionhallName
    })
      .then((response) => {
        alert('Data saved successfully');
        setDialogOpen(false);
      })
      .catch((error) => {
        console.error('Error saving data:', error);
      });
  };

  return (
    <div className={dialogOpen ? 'blur-background' : ''} style={{ display: "flex" }}>
      <div>
        <Sidebar />
      </div>
      <div className="home">
        <Navlink />
        {isLoggedIn ? (
          <>
            <Rightslider />
            <hr />
            <div className="count-boxes">
              <div className="count-box1" style={{ backgroundColor: selectedcolour }}>
                <h3>Ongoing Events</h3>
                <h1>
                  <b>
                    <span className="count-number" id="ongoingCount">
                      {counts.ongoingCount}
                    </span>
                  </b>
                </h1>
              </div>
              <div className="count-box2" style={{ backgroundColor: selectedcolour }}>
                <h3>Booked Events from Tomorrow</h3>
                <h1>
                  <b>
                    <span className="count-number" id="bookedCount">
                      {counts.bookedCount}
                    </span>
                  </b>
                </h1>
              </div>
              <div className="count-box3" style={{ backgroundColor: selectedcolour }}>
                <h3>Events Done</h3>
                <h1>
                  <b>
                    <span className="count-number" id="doneCount">
                      {counts.doneCount}
                    </span>
                  </b>
                </h1>
              </div>
            </div>
            <br />
            <div className="pb">
              <div className="pf">
                <Pie />
              </div>
              <div className="bc">
                <BarChart />
              </div>
            </div>
            <p>{logoutStatus}</p>
          </>
        ) : (
          <p>Loading...</p>
        )}
      </div>
      <Dialog open={dialogOpen} onClose={handleDialogClose} disableBackdropClick>
        <DialogTitle>Profile Details</DialogTitle>
        <DialogContent>
          <TextField
            label="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            fullWidth
          />
          <TextField
            label="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            fullWidth
          />
          <TextField
            label="Function Hall Name"
            value={functionhallName}
            onChange={(e) => setFunctionhallName(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          
          <Button onClick={handleProfileSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
