import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Pay.css"; // Make sure to import the CSS file
import thoram from "../images/thoram.jpg";
import "./booking.css";
import Sidebar from "./Sidebar";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button, Checkbox, FormControlLabel
} from "@mui/material";
import { Loading } from "./loading";
import Navlink from "./Navlink";
import { colourContext } from "../context/useecontext";
import { Rightslider } from "./rightslider";

const BookingComponent = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setusername] = useState("");
  const navigate = useNavigate();
  const { selectedcolour } = useContext(colourContext);

  useEffect(() => {
    Axios.get("https://api.functionparks.com/home", { withCredentials: true })
      .then((response) => {
        if (response.data.loggedIn) {
          setusername(response.data.user.username);
        } else {
          navigate("/");
        }
      })
      .catch((error) => {
        console.error("Error checking authentication:", error);
        navigate("/");
      });
  }, []);

  const [selectedDate, setSelectedDate] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [availableSlots, setAvailableSlots] = useState([]);
  const [allSlotsFilled, setAllSlotsFilled] = useState(false);
  const [selectedSlotsMap, setSelectedSlotsMap] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [formError, setFormError] = useState("");
  const [today] = useState(new Date().toISOString().split("T")[0]);
  const [noSlotsAvailableDialogOpen, setNoSlotsAvailableDialogOpen] =
    useState(false);
  const [purposeOfEvent, setPurposeOfEvent] = useState("");
  const [eventDetails, setEventDetails] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingpage, setLoadingpage] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [addonitems, setaddonitems] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});
  const [addonpreview, setaddonpreview] = useState(false);

  useEffect(() => {
    if (username) {
      Axios.get('https://api.functionparks.com/api/getaddonitems', {
        params: { username }
      }).then((response) => {
        const items = response.data;
        setaddonitems(items);
        const initialCheckedItems = items.reduce((acc, item) => {
          acc[item] = false;
          return acc;
        }, {});
        setCheckedItems(initialCheckedItems);
      }).catch((error) => {
        if (error.response && error.response.status === 404) {
          console.log("No addon items found for this user");
        } else {
          console.error('Error fetching addon items:', error);
        }
      });
    }
  }, [username]);

   
  const handleCheckboxChange = (event) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked
    });
  };

  useEffect(() => {
    if (username && selectedDate) {
      setLoading(true);
      axios
        .get(`https://api.functionparks.com/slots?date=${selectedDate}`, {
          params: { username: username }, // Send username as a query parameter
        })
        .then((response) => {
          const slots = response.data;
          setAvailableSlots(slots);
          setAllSlotsFilled(slots.length === 0);
          // Reset selectAll when date changes
          setSelectAll(false);
          // Show "No Slots Available" dialog if all slots are filled
          if (slots.length === 0) {
            setNoSlotsAvailableDialogOpen(true);
          }
        })
        .catch((error) => {
          if (error.response.data === "No slots declared for this user") {
            alert("No slots declared for this user");
            setAvailableSlots([]);
          }
          console.error("Error fetching available slots", error);
        })
        .finally(() => {
          setLoading(false); // Set loading to false when fetch is complete
        });
    }
  }, [username, selectedDate]);

  const handleSlotSelection = (slot) => {
    if (availableSlots.includes(slot)) {
      const updatedSelectedSlots = { ...selectedSlotsMap };

      if (!updatedSelectedSlots[selectedDate]) {
        updatedSelectedSlots[selectedDate] = [];
      }

      if (updatedSelectedSlots[selectedDate].includes(slot)) {
        updatedSelectedSlots[selectedDate] = updatedSelectedSlots[
          selectedDate
        ].filter((s) => s !== slot);
      } else {
        updatedSelectedSlots[selectedDate].push(slot);
      }

      setSelectedSlotsMap(updatedSelectedSlots);
    } else {
      // If the selected slot is filled, show dialog
      setNoSlotsAvailableDialogOpen(true);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      // Deselect all slots
      setSelectedSlotsMap({ ...selectedSlotsMap, [selectedDate]: [] });
    } else {
      // Select all slots
      setSelectedSlotsMap({
        ...selectedSlotsMap,
        [selectedDate]: availableSlots,
      });
    }

    setSelectAll(!selectAll);
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleBooking = () => {
    if (
      !name ||
      !phoneNumber ||
      !email ||
      !selectedDate ||
      !Object.keys(selectedSlotsMap).length
    ) {
      setFormError("All fields are required.");
      alert("All fields are required.");
      return;
    }

    if (phoneNumber.length < 10) {
      setFormError(
        "Please enter a valid Phone Number with at least 10 digits."
      );
      alert("Please enter a valid Phone Number with at least 10 digits.");
      return;
    }

    if (!validateEmail(email)) {
      setFormError("Please enter a valid email address.");
      alert("Please enter a valid email address.");
      return;
    }

    if (allSlotsFilled) {
      // If all slots are filled, show dialog
      setNoSlotsAvailableDialogOpen(true);
      return;
    }
    setLoadingpage(true);
    // Validate form fields
    // Make a request to book the selected slot(s) for multiple days
    const bookingData = Object.entries(selectedSlotsMap).flatMap(
      ([date, slots]) =>
        slots.map((timeSlot) => ({
          username,
          name,
          phoneNumber,
          email,
          date,
          timeSlot,
          purposeOfEvent,
          eventDetails,
        }))
    );

    axios
      .post("https://api.functionparks.com/book", {
        bookings: bookingData,
      })
      .then((response) => {
        // Handle successful booking
        console.log("Booking successful", response.data);
        alert("Booking successful");
        // Reset form fields
        setName("");
        setPhoneNumber("");
        setEmail("");
        setSelectedDate("");
        setAvailableSlots([]);
        setAllSlotsFilled(false);
        setSelectedSlotsMap({});
        setSelectAll(false);
        setFormError("");
        setPurposeOfEvent("");
        setEventDetails("");
        setPreviewOpen(false);
      })
      .catch((error) => {
        // Handle booking error
        console.error("Booking error", error);
        alert("Booking error");
      })
      .finally(() => {
        // Set loading to false after booking process is complete
        setLoadingpage(false);
      });
  };

  const handleDeleteDate = (date) => {
    const updatedSelectedSlots = { ...selectedSlotsMap };
    delete updatedSelectedSlots[date];
    setSelectedSlotsMap(updatedSelectedSlots);
  };

 

  const handleOpenPreview = () => {
    if (
      !name ||
      !phoneNumber ||
      !email ||
      !selectedDate ||
      !Object.keys(selectedSlotsMap).length
    ) {
      setFormError("All fields are required.");
      alert("All fields are required.");
      return;
    }
    setPreviewOpen(true);
  };

  const handleClosePreview = () => {
    setPreviewOpen(false);
  };

  const handleopenaddonitems = () => {
    setaddonpreview(true);
  };

  const handleCloseaddonitems = () => {
    setaddonpreview(false);
  };

  const handleConfirm = () => {
    console.log('Selected items:', checkedItems);
    // Add your confirm logic here
    handleCloseaddonitems();
  };
  
  return (
    <div>
      {loadingpage ? (
        <Loading>Booking Slots</Loading>
      ) : (
        <div style={{ display: "flex", width: "100%" }}>
          <div>
            <Sidebar />
          </div>

          <div className="w-100">
            <Navlink />
            <Rightslider />
            <div className="d-flex flex-column flex-md-row justify-content-around  mt-5">
              <div className="">
                <div className=" ">
                  <div className="">
                    <div className="container ">
                      <h2 className="">Enter customer Details</h2>

                      <div className="custom-input-group cip">
                        <label className="">First Name</label>
                        <input
                          required
                          type="text"
                          name="text"
                          autoComplete="off"
                          className="custom-input"
                          onChange={(e) => setName(e.target.value)}
                          value={name}
                        />
                      </div>

                      <div className="custom-input-group cip">
                        <label className="">Phone</label>
                        <input
                          required
                          type="text"
                          name="text"
                          autoComplete="off"
                          className="custom-input"
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          value={phoneNumber}
                          pattern="^\+?\d{1,3}\d{9}$"
                        />
                      </div>

                      <div className="custom-input-group cip ">
                        <label className="">Email</label>
                        <input
                          required
                          type="text"
                          name="text"
                          autoComplete="off"
                          className="custom-input"
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                          pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
                        />
                      </div>

                      <div className="purposeofevent">
                        <label htmlFor="purposeOfEvent">
                          Purpose of Event:
                        </label>
                        <br />
                        <select
                          id="purposeOfEvent"
                          className="sol"
                          value={purposeOfEvent}
                          onChange={(e) => setPurposeOfEvent(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="Marriage">Marriage</option>
                          <option value="Engagement">Engagement</option>
                          <option value="Reception">Reception</option>
                          <option value="Baby Shower">Baby Shower</option>
                          <option value="Birthday">Birthday</option>
                          <option value="Anniversary Day">
                            Anniversary Day
                          </option>
                          <option value="Naming Ceremony">
                            Naming Ceremony
                          </option>
                        </select>
                      </div>

                      <div className="eventdetails">
                        <textarea
                          id="eventDetals"
                          placeholder="Tell us more about your event"
                          className="event-details"
                          value={eventDetails}
                          onChange={(e) => setEventDetails(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bookdatess">
                <div>
                  <Link
                    to="/cal"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <p
                      style={{ backgroundColor: selectedcolour }}
                      className="text-center availableslotss"
                    >
                      Click here to see Available Slots
                    </p>
                  </Link>
                </div>

                <br />

                <div className="">
                  <h1 className="boo"> Book Dates</h1>
                </div>

                <div className="display-11 ">
                  <input
                    type="date"
                    onChange={(e) => setSelectedDate(e.target.value)}
                    value={selectedDate}
                    required
                    className="dat"
                    min={today} // Set min attribute to today's date
                  />
                  <div>
                    <div className="ts">
                      <b>Time Slots</b>
                      {loading && <div className="loading">Loading...</div>}
                      {allSlotsFilled ? (
                        <p>All slots are filled for the selected date.</p>
                      ) : (
                        <>
                          {selectedDate && (
                            <>
                              <div className="select-timeslots-head">
                                <button
                                  className={`btn ${
                                    selectAll
                                      ? "btn-secondary selected"
                                      : "btn-outline-secondary"
                                  }`}
                                  onClick={handleSelectAll}
                                >
                                  {selectAll
                                    ? "Slots Selected"
                                    : "Select All Slots"}
                                </button>
                              </div>

                              {availableSlots.map((slot) => (
                                <button
                                  key={slot}
                                  className={`btn btn-primary m-2 ${
                                    selectedSlotsMap[selectedDate]?.includes(
                                      slot
                                    )
                                      ? "selected"
                                      : ""
                                  }`}
                                  onClick={() => handleSlotSelection(slot)}
                                  style={{
                                    backgroundColor: selectedSlotsMap[
                                      selectedDate
                                    ]?.includes(slot)
                                      ? "#28a745"
                                      : "#007bff",
                                    color: selectedSlotsMap[
                                      selectedDate
                                    ]?.includes(slot)
                                      ? "#fff"
                                      : "#fff",
                                  }}
                                >
                                  {slot}
                                </button>
                              ))}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                <div>
                  <div className=" ">
                    <p style={{ color: "black" }}>
                      Selected Dates and Time Slots:
                    </p>
                    <ul className="selectedslotss">
                      {Object.entries(selectedSlotsMap).map(([date, slots]) => (
                        <li key={date} className="">
                          <div className="selected-date-header">
                            <strong>{date}</strong>
                            <button
                              className="btn btn-danger deletebutton"
                              onClick={() => handleDeleteDate(date)}
                            >
                              Delete
                            </button>
                          </div>
                          <ul className="time-slots-list">
                            {slots.map((timeSlot) => (
                              <li key={`${date}-${timeSlot}`}>{timeSlot}</li>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div>
                    <button
                      className="mb-3 text-white"
                      onClick={handleopenaddonitems}
                      style={{ backgroundColor: selectedcolour }}
                    >
                      <span>Add on items</span>
                    </button>

                    <button
                      className="mb-3 text-white"
                      onClick={handleOpenPreview} 
                      disabled={!selectedDate}
                      style={{ backgroundColor: selectedcolour }}
                    >
                      <span>Preview Booking</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* No Slots Available Dialog */}
          <Dialog
            open={noSlotsAvailableDialogOpen}
            onClose={() => setNoSlotsAvailableDialogOpen(false)}
          >
            <DialogTitle>No Slots Available</DialogTitle>
            <DialogContent>
              <p>
                All slots are filled for the selected date. Please choose
                another date.
              </p>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setNoSlotsAvailableDialogOpen(false)}>
                OK
              </Button>
            </DialogActions>
          </Dialog>

          {/* Preview Dialog */}
          <Dialog
            open={previewOpen}
            onClose={handleClosePreview}
            fullWidth
            maxWidth="md"
          >
            <DialogTitle>Preview Booking</DialogTitle>
            <DialogContent>
              {/* Add preview content here */}
              <p>Name: {name}</p>
              <p>Phone Number: {phoneNumber}</p>
              <p>Email: {email}</p>
              <p>Date: {selectedDate}</p>
              <p>Purpose of Event: {purposeOfEvent}</p>
              <p>Event Details: {eventDetails}</p>
              <p>Selected Slots:</p>
              <ul>
                {Object.entries(selectedSlotsMap).map(([date, slots]) => (
                  <li key={date}>
                    <strong>{date}</strong>
                    <ul>
                      {slots.map((timeSlot) => (
                        <li key={`${date}-${timeSlot}`}>{timeSlot}</li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClosePreview}>Close</Button>
              <Button onClick={handleBooking} disabled={!selectedDate}>
                Confirm Booking
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
                open={addonpreview}
                onClose={handleCloseaddonitems}
                fullWidth
                maxWidth="sm"
              >
                <DialogTitle>Add-ons</DialogTitle>
                <DialogContent>
                  {addonitems.map((item) => (
                    <FormControlLabel
                      key={item}
                      control={
                        <Checkbox
                          checked={checkedItems[item]}
                          onChange={handleCheckboxChange}
                          name={item}
                        />
                      }
                      label={item}
                    />
                  ))}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseaddonitems} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={handleConfirm} color="primary">
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
        </div>
      )}
    </div>
  );
};

export default BookingComponent;
