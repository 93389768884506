import React, { useState, useEffect } from 'react';
import {Link, useNavigate } from 'react-router-dom';
import Axios from 'axios';
import { Rightslider } from './rightslider';

const Gallery = () => {
  
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    Axios.get('https://api.functionparks.com/home', { withCredentials: true })
      .then((response) => {
        if (response.data.loggedIn) {
          setIsLoggedIn(true);
        } else {
          navigate('/');
        }
      })
      .catch((error) => {
        console.error('Error checking authentication:', error);
        navigate('/');
      });
  }, [navigate]);
  // Array of image URLs for function hall booking
  const functionHallImages = [
    'url_to_image1.jpg',
    'url_to_image2.jpg',
    'url_to_image3.jpg',
    // Add more image URLs as needed
  ];

  return (
    <div>
      <Link to="/Pages">
        <button>Back</button>
      </Link>
      <Rightslider/>
      <h2>Function Hall Booking</h2>
      {functionHallImages.map((imageUrl, index) => (
        <img key={index} src={imageUrl} alt={`Image ${index + 1}`} style={{ maxWidth: '100%', marginBottom: '10px' }} />
      ))}
     
      
    </div>
  );
};

export default Gallery;