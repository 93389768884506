import React, { useState, useEffect } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import Axios from 'axios';
import { Pie } from 'react-chartjs-2';
import './Pie.css'

const PieChart = () => {
  const [username, setusername] = useState('');
  const navigate = useNavigate();
  const [counts, setCounts] = useState({
    ongoingCount: 0,
    bookedCount: 0,
    doneCount: 0
  });

  useEffect(() => {
    Axios.get('https://api.functionparks.com/home', { withCredentials: true })
      .then((response) => {
        if (response.data.loggedIn) {
          setusername(response.data.user.username)
        } else {
          navigate('/');
        }
      })
      .catch((error) => {
        console.error('Error checking authentication:', error);
        navigate('/');
      });
  }, []);


  useEffect(() => {
    if (username){
      Axios.get(`https://api.functionparks.com/api/events/count/${username}`)
      .then((response) => {
        if (response.data.success) {
          setCounts({
            ongoingCount: response.data.ongoingCount,
            bookedCount: response.data.bookedCount,
            doneCount: response.data.doneCount
          });
        } else {
          console.error('Error fetching event counts:', response.data.error);
        }
      })
      .catch((error) => {
        console.error('Error fetching event counts:', error);
      });
    }
  }, [username]);
  
  const data = {
    labels: ['Onging Events', 'Booked Events from Tomorrow', 'Events Done'],
    datasets: [
      {
        data: [counts.ongoingCount, counts.bookedCount, counts.doneCount],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
  };

  return (
    <div className="pie-chart-container pp">
      <Pie data={data} options={options} />
    </div>
  );
};

export default PieChart;
