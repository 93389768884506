import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Axios from "axios";
import Navlink from "./Navlink";
import { Tab, Button } from "react-bootstrap";
import "./profile.css";
import Sidebar from "./Sidebar";
import lit from "../images/lit.jpg";
import { colourContext } from "../context/useecontext";
import { Rightslider } from "./rightslider";

const Profile = () => {
  const [username, setusername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [functionhallName, setFunctionhallName] = useState("");
  const navigate = useNavigate();
  const { selectedcolour, functionhall } = useContext(colourContext);

  useEffect(() => {
    Axios.get("https://api.functionparks.com/home", { withCredentials: true })
      .then((response) => {
        if (response.data.loggedIn) {
          setusername(response.data.user.username);
        } else {
          navigate("/");
        }
      })
      .catch((error) => {
        console.error("Error checking authentication:", error);
        navigate("/");
      });
  }, []);

  useEffect(() => {
    if (username) {
      Axios.get("https://api.functionparks.com/api/profile", {
        params: { username: username },
      })
        .then((response) => {
          const userData = response.data.user;
          setFirstName(userData.firstname);
          setLastName(userData.lastname);
          setFunctionhallName(userData.functionhallName);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });
    }
  }, [username]);

  const handleSaveChanges = () => {
    functionhall(functionhallName);
    Axios.post("https://api.functionparks.com/api/profile", {
      username,
      firstname: firstName,
      lastname: lastName,
      functionhallName,
    })
      .then((response) => {
        alert("Data saved successfully");
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };

  return (
    <div style={{ display: "flex" }}>
      <div>
        <Sidebar />
      </div>
      <div className="w-100">
        <Navlink />
        <Rightslider />

        <div>
          <div
            className="profilecontainerr container "
            style={{ marginTop: "20px" }}
          >
            <h2>Profile Details</h2>

            <label>Name:</label>
            <br />
            <input
              className="profileinputss"
              required
              type="text"
              name="text"
              autoComplete="off"
              value={firstName}
              placeholder="First Name"
              onChange={(e) => setFirstName(e.target.value)}
            />
            <br />
            <label>Last Name:</label>
            <br />
            <input
              className="profileinputss"
              required
              type="text"
              name="text"
              autoComplete="off"
              value={lastName}
              placeholder="Last Name"
              onChange={(e) => setLastName(e.target.value)}
            />
            <br />

            <label>Function Hall Name:</label>
            <br />
            <input
              className="profileinputss"
              required
              type="text"
              name="text"
              autoComplete="off"
              value={functionhallName}
              placeholder="Function Hall Name"
              onChange={(e) =>
                setFunctionhallName(e.target.value.toUpperCase())
              } // Convert to uppercase
            />

            <br />
            <br />

            <div>
              <div
                className="buttonhome"
                onClick={handleSaveChanges}
                style={{
                  backgroundColor: selectedcolour || "#0080FE",
                  cursor: "pointer",
                }}
              >
                Save
              </div>
            </div>
            <br />
            <div className="buttonhome">
              <Link to="/slots">
                <div
                  className="buttonhome"
                  style={{
                    backgroundColor: selectedcolour || "#0080FE",
                    cursor: "pointer",
                    width: "110px",
                  }}
                >
                  Declare Slots
                </div>
              </Link>
                  <br/>
              <Link to="/addonitems">
                <div
                  className="buttonhome"
                  style={{
                    backgroundColor: selectedcolour || "#0080FE",
                    cursor: "pointer",
                    width: "180px",
                  }}
                >
                  Declare Add on Items
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
