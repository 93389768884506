import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import Chart from "chart.js/auto";
import './BarChart.css';

const BarChart = () => {
  const [bookingData, setBookingData] = useState([]);
  const [username, setusername] = useState('');

  useEffect(() => {
    Axios.get('https://api.functionparks.com/home', { withCredentials: true })
      .then((response) => {
        if (response.data.loggedIn) {
          setusername(response.data.user.username)
        } 
      })
      .catch((error) => {
        console.error('Error checking authentication:', error);
      });
  }, []);

  useEffect(() => {
    if (username){
        Axios.get('https://api.functionparks.com/api/bookings/countByMonth', {
      params: {
        username: username // Pass the username here
      }
    })
    .then((response) => {
      if (response.data.success) {
        setBookingData(response.data.data);
        
      } else {
        console.error('Error fetching booking data:', response.data.error);
      }
    })
    .catch((error) => {
      console.error('Error fetching booking data:', error);
    });
  }
  }, [username]);

  useEffect(() => {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const labels = months;
    const counts = Array.from({ length: 12 }, () => 0); // Initialize counts for each month with 0

    // Update counts array with actual counts based on fetched data
    bookingData.forEach(item => {
      counts[item.month - 1] = item.count;
    });

    const bar_ctx = document.getElementById('bar-chart').getContext('2d');

    const purple_orange_gradient = bar_ctx.createLinearGradient(0, 0, 0, 600);
    purple_orange_gradient.addColorStop(0, 'orange');
    purple_orange_gradient.addColorStop(1, 'purple');

    const bar_chart = new Chart(bar_ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{
          label: '# No of Bookings',
          data: counts,
          backgroundColor: purple_orange_gradient,
          hoverBackgroundColor: purple_orange_gradient,
          hoverBorderWidth: 2,
          hoverBorderColor: 'purple'
        }]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    });

    return () => {
      bar_chart.destroy();
    };
  }, [bookingData]);

  return (
    <div className='gf'>
      <canvas id="bar-chart" ></canvas>
    </div>
  );
};

export default BarChart;
