import React, { useState, useEffect } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import Axios from 'axios';
import './review.css'
import user from './assets/user2.avif';
import { IoMdStar } from "react-icons/io";
import Aos from 'aos';
 import Sidebar from "./Sidebar"
 import Navlink from './Navlink';
import { Rightslider } from './rightslider';
const generateStars = (rating) => {
  const stars = [];
  for (let i = 0; i < rating; i++) {
    stars.push(<IoMdStar className="star" key={i} />);
  }
  return stars;
 
};
 
const Card = (props) => (
    <div  data-aos="fade-up" className="card">
      <div className="testimo">
        <p className="comment">{props.content}</p>
      </div>
      <div className="card-content">
      <div className="d-flex flex-row align-items-center">
        <img className="user" src={props.imgUrl} alt="i" />
        <h6>{props.name}</h6>
      </div>
      <div className="stars">
      {generateStars(props.rating)}
       
      </div>
      </div>
    </div>
  );
 
 
const Reviews = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    Axios.get('https://api.functionparks.com/home', { withCredentials: true })
      .then((response) => {
        if (response.data.loggedIn) {
          setIsLoggedIn(true);
        } else {
          navigate('/');
        }
      })
      .catch((error) => {
        console.error('Error checking authentication:', error);
        navigate('/');
      });
  }, [navigate]);
 
  useEffect(() => {
    Aos.init({duration: 1000});
}, []);
 
    const cardsData = [
        {
            id: 1,
            name: 'Joe',
            content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ad autem ipsam, quas molestias consequatur,',
            imgUrl: user,
            rating: 4,
          },
          {
            id: 2,
            name: 'John',
            content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ad autem ipsam, quas molestias consequatur,Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ad autem ipsam, quas molestias consequatur,Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ad autem ipsam, quas molestias consequatur,',
            imgUrl: user,
            rating: 3,
          },
          {
            id: 3,
            name: 'Jack',
            content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ad autem ipsam, quas molestias consequatur,',
            imgUrl: user,
            rating: 5,
          },
          {
            id: 4,
            name: 'Jaime',
            content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ad autem ipsam, quas molestias consequatur,',
            imgUrl: user,
            rating: 2,
          },
          {
            id: 5,
            name: 'Jack',
            content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ad autem ipsam, quas molestias consequatur,',
            imgUrl: user,
            rating: 4,
          },
          {
            id: 6,
            name: 'Jaime',
            content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ad autem ipsam, quas molestias consequatur,',
            imgUrl: user,
            rating: 5,
          },
         
    ]
 
    return (
      <div style={{display:"flex"}}>
        <div>
<Sidebar/>
<Rightslider/>
        </div>
     
  
        <div className="review">
        <Navlink/>
        <h2 className="text-center">Reviews</h2>
  
        <hr className="mt-3 mb-3" />
            {/* <div className="cards-container">
    {cardsData.map((card) => (
      <Card
        key={card.id}
        name={card.name}
        content={card.content}
        imgUrl={card.imgUrl}
      />
    ))}
 
  </div> */}
  <div className="hi">
  {cardsData.map((card) => (
      <Card
        key={card.id}
        name={card.name}
        content={card.content}
        imgUrl={card.imgUrl}
        rating={card.rating}
      />
    ))}
  </div>
 
        </div>
        </div>
    );
}
 
export default Reviews;