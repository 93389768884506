import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {Home} from './Components/Home';
import MyBookings from './Components/MyBookings';
import Pages from './Components/Pages';
import Reviews from './Components/Reviews';
import Services from './Components/Services';
import Payment from './Components/Payment';
import Profile from "./Components/Profile";
import Gallery from './Components/Gallery';
import Aboutus from "./Components/Aboutus";
import Contactus from "./Components/Contactus";
import 'aos/dist/aos.css';
import Addbookings from "./Components/Addbookings";
import Cal from "./Components/Cal";
import {Login} from "./Components/Login";
import  Completed_slots  from "./list views/completed_slots";
import  Deleted_slots  from "./list views/deleted_slots";
import { Upcoming_events } from "./list views/upcoming_events";
import { Transaction } from "./list views/transaction";
import { Forgetpassword } from "./Components/forgetpassword";
import { Signup } from "./Components/sigup";
import { ColourContextProvider } from "./context/useecontext";
import { SLots } from "./Components/admin";
import { Addonitems } from "./Components/addonitems";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <ColourContextProvider>
          <Routes>
        <Route path='/' element={<Login/>}/>
        <Route path='/Home' element={<Home/>}/>
        <Route path="/forgetpassword" element={<Forgetpassword/>}/>
        <Route path='/homep' element={<Home/>}/>
        <Route path='/cal' element={<Cal/>}/>
        <Route path='/MyBookings' element={<MyBookings/>}/>
        <Route path='/Pages' element={<Pages/>}/>
        <Route path='/Gallery' element={<Gallery/>}/>
        <Route path='/Aboutus' element={<Aboutus/>}/>
        <Route path='/Reviews' element={<Reviews/>}/>
        <Route path='/Services' element={<Services/>}/>
        <Route path='/payment' element={<Payment/>}/>
        <Route path='/Contactus' element={<Contactus/>}/>
        <Route path='/bookings' element={<Addbookings/>}/>
        <Route path='/profile' element={<Profile/>}/>
        <Route path='/completedslots' element={<Completed_slots/>}/>
        <Route path='/deletedslots' element={<Deleted_slots/>}/>
        <Route path='/upcomingslots' element={<Upcoming_events/>}/>
        <Route path='/transaction' element={<Transaction/>}/>
        <Route path='/signup' element={<Signup/>}/>
        <Route path="/slots" element={<SLots />} />
        <Route path="/addonitems" element={<Addonitems />} />


          <Route path="*" element={<>Not Found</>} />
          </Routes>
        </ColourContextProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
