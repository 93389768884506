
import './Sidebar.css';
import { FaBars, FaHome } from "react-icons/fa";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";  
import { IoStarSharp } from "react-icons/io5";
import { MdPayment } from "react-icons/md";
// import { LuFiles } from "react-icons/lu";
import {PiUsersThreeBold } from "react-icons/pi";
// import logolms from "../Sidebar/img/logolms.png";
import { FaCircleUser } from "react-icons/fa6";
// import { MdOutlineAdminPanelSettings } from "react-icons/md";
// import { SlCalender } from "react-icons/sl";
//import { MdOutlinePayment } from "react-icons/md";
//import { GrBusinessService } from "react-icons/gr";
import { ImCross } from "react-icons/im";
import { FaCheckToSlot } from "react-icons/fa6";
import React, { useState, useEffect,useContext } from 'react';
import { NavLink,useNavigate } from 'react-router-dom';
import Axios from 'axios';
import {colourContext} from '../context/useecontext';
import Tooltip from 'rc-tooltip'; 




const routes = [
  {
    path:"/home",
    name:"Home",
    icon:<FaHome />,
  },
  {
    path:"/cal",
    name:"My Bookings",
    icon:<FaCheckToSlot />
  },
  {
    path:"/payment",
    name:"Payment",
    icon:<MdPayment />
  },
  {
    path:"/pages",
    name:"Pages",
    icon:<PiUsersThreeBold />
  },
  {
    path:"/reviews",
    name:"Reviews",
    icon:<IoStarSharp />
  },
  // {
  //   path:"/services",
  //   name:"Services",
  //   icon:<GrBusinessService />
  // },
  // {
  //   path:"/payments",
  //   name:"Payments",
  //   icon:<MdOutlinePayment />
  // },
  {
    path:"/profile",
    name:"Profile",
    icon:<FaCircleUser />
  },
];

const SideBar = ({ children }) => {
  const [username, setusername] = useState('');
  const navigate = useNavigate();
  const { selectedcolour } = useContext(colourContext);
  
  useEffect(() => {
    Axios.get('https://api.functionparks.com/home', { withCredentials: true })
      .then((response) => {
        if (response.data.loggedIn) {
          setusername(response.data.user.username)
        } else {
          navigate('/');
        }
      })
      .catch((error) => {
        console.error('Error checking authentication:', error);
        navigate('/');
      });
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <>
      <div className="main-container">
        <motion.div
          animate={{
            width: isOpen ? "200px" : "45px",
            transition: {
              duration: 0.5,
              type: "spring",
              damping: 10,
            },
          }}
          className={`sidebar`} 
        >
          <div className="top_section">
            <AnimatePresence>
              {isOpen && (
                <motion.h1
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="logo"
                >
                  {/* <img src="" alt="makenow" style={{width : '40px', height: '40px' }}/> */}
                  <h3 className="mt-3">Menu</h3>
                </motion.h1>
              )}
            </AnimatePresence>

            <div className="bars">
              {isOpen ? <ImCross style={{ cursor: 'pointer' }} onClick={toggle} /> : <FaBars style={{ cursor: 'pointer' }} onClick={toggle} />}
            </div>
          </div>
          
          <section className="routes">
            {routes.map((route, index) => (
              <NavLink
                to={route.path}
                key={index}
                className="link"
                activeClassName="active"
                style={{
                  textDecoration: 'none',
                  backgroundColor: route.path === window.location.pathname ? selectedcolour : 'transparent'
                }}
              >
                <Tooltip
                  placement="topLeft"
                  trigger={['hover']}
                  overlayStyle={{ width: '90px' }}
                  overlay={<div>{route.name}</div>}
                >
                  <div className="icon">{route.icon}</div>
                </Tooltip>

                <AnimatePresence>
                  {isOpen && (
                    <motion.div
                      variants={showAnimation}
                      initial="hidden"
                      animate="show"
                      exit="hidden"
                      className="link_text"
                    >
                      {route.name}
                    </motion.div>
                  )}
                </AnimatePresence>
              </NavLink>
            ))}
          </section>
        </motion.div>

        <main className={isOpen ? 'openn' : 'closedd'}>{children}</main>
      </div>
    </>
  );
};

export default SideBar;
