import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Sidebar from "../Components/Sidebar";
import { useNavigate } from 'react-router-dom';
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy } from 'react-table';
import { Rightslider } from '../Components/rightslider';

const Deleted_slots = () => {
  const [deletedSlots, setDeletedSlots] = useState([]);
  const [username, setUsername] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    axios.get('https://api.functionparks.com/home', { withCredentials: true })
      .then((response) => {
        if (response.data.loggedIn) {
          setUsername(response.data.user.username)
        } else {
          navigate('/');
        }
      })
      .catch((error) => {
        console.error('Error checking authentication:', error);
        navigate('/');
      });
  }, []);

  useEffect(() => {
    const fetchDeletedSlots = async () => {
      try {
        const response = await axios.get('https://api.functionparks.com/api/deletedSlots', { params: { username: username } });
        const formattedEvents = response.data.map(event => ({
          ...event,
          date: formatDate(event.date) // Formatting date here
        }));
        setDeletedSlots(formattedEvents);
      } catch (error) {
        console.error('Error fetching deleted slots:', error);
      }
    };

    if (username) {
      fetchDeletedSlots();
    }
  }, [username]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year}`;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Booking ID',
        accessor: 'booking_id',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Phone Number',
        accessor: 'phone_number',
      },
      {
        Header: 'Date',
        accessor: 'date',
      },
      {
        Header: 'Time Slot',
        accessor: 'timeslot',
      },
      {
        Header: 'Purpose of Event',
        accessor: 'purposeOfEvent',
      },
      {
        Header: 'Event Details',
        accessor: 'eventDetails',
      },
    ],
    []
  );

  const data = React.useMemo(() => deletedSlots, [deletedSlots]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    { columns, data, initialState: { sortBy: [{ id: 'date', desc: true }] } },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  const { globalFilter } = state;

  const handleSearchChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 0);

  return (
    <div style={{ display: "flex" }}>
      <div>
        <Sidebar />
        <Rightslider/>
      </div>
      <div style={{ width: "100%", marginTop: "15px" }}>
        <Link style={{ textDecoration: "none" }} to="/cal">
          <div style={{ borderRadius: "5px", width: "100px", height: "30px", backgroundColor: "rgb(90, 4, 65)", color: "white" }}>
            <btn style={{ marginLeft: "27px" }}>Back</btn>
          </div>
        </Link>
        <div>
          <h2  className='mt-2'>Deleted Slots</h2>
        </div>

        <div style={{ marginTop: "20px", overflowY: "scroll" }}>
          <input
            className='searchbarrr'
            placeholder="Search..."
            value={globalFilter || ''}
            onChange={e => {
              handleSearchChange(e.target.value);
            }}
          />
          <table {...getTableProps()} className="table">
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      <span>
                        {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Deleted_slots;
