import React, { useState, useContext } from 'react';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import { RiArrowLeftDoubleFill } from "react-icons/ri";
import { colourContext } from '../context/useecontext';
import { Link } from 'react-router-dom';

export const Rightslider = () => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const { selectedcolour } = useContext(colourContext);


  const toggleSidebar = () => {
    setOpenSidebar(!openSidebar);
  };

  return (
    <div style={{ position: "fixed", top: "50%", right: 0, transform: "translateY(-50%)", zIndex: 999 }}>
      <div onClick={toggleSidebar} style={{ cursor: 'pointer' }}>
        <RiArrowLeftDoubleFill style={{ color: "black", fontSize: '3rem' }} />
      </div>

      <Drawer
        anchor="right"
        open={openSidebar}
        onClose={() => setOpenSidebar(false)}
      >
         <div className='d-flex flex-column justify-content-center align-items-center' style={{ height: "100vh", padding: "20px" }}>
         <Link to='/bookings'>
            <Button variant="contained" style={{ marginBottom: "10px", backgroundColor: selectedcolour }}>Booking</Button>
         </Link>
         <Link to='/payment'>
         <Button variant="contained" style={{ backgroundColor: selectedcolour }}>Payment</Button>
         </Link>
        </div>
      </Drawer>
    </div>
  );
};
