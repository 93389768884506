import React, { useState, useEffect } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import Axios from 'axios';
// import { useState } from "react";
import Tab from "react-bootstrap/Tab";
// import Tabs from "react-bootstrap/Tabs";
import Nav from "react-bootstrap/Nav";
import { Button } from "react-bootstrap";
import service1 from "../images/img-empty-state-video.png";
import service2 from "../images/img-empty-state-document.png";
import service3 from "../images/img-empty-state-package.png";
import service4 from "../images/img-empty-state-query.png";
import service5 from "../images/img-empty-state-webinar.png";
import Badge from "react-bootstrap/Badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Rightslider } from './rightslider';
const Services = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    Axios.get('https://api.functionparks.com/home', { withCredentials: true })
      .then((response) => {
        if (response.data.loggedIn) {
          setIsLoggedIn(true);
        } else {
          navigate('/');
        }
      })
      .catch((error) => {
        console.error('Error checking authentication:', error);
        navigate('/');
      });
  }, [navigate]);
  return (
    <div className="pageContainer">
    <Rightslider/>
      <div className="p-30">
        <h1 className="page-title">Services</h1>
        <div className="tabs-container">
          <Tab.Container id="left-tabs-example" defaultActiveKey="call">
            <div className="flexJustify">
              <Nav className="flex-row nav-tabs">
                <Nav.Item>
                  <Nav.Link eventKey="call">1:1 Call</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Subscriptions">Subscriptions</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Webinar">Webinar</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="PriorityDM">Priority DM</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="DigitalProduct">Digital Product</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Package">Package</Nav.Link>
                </Nav.Item>
              </Nav>
              <div className="btnDiv">
                <Link>
                  <Button variant="dark" className="btnRight">
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="iconGap iconSmall"
                    />
                    Add new
                  </Button>
                </Link>
                <Link>
                  <Button variant="outline-dark"> Templates</Button>
                </Link>
              </div>
            </div>

            <Tab.Content className="serviceDivContainer">
              <Tab.Pane eventKey="call">
                <div className="flexCenter bg-white">
                  <img src={service1} alt="service" />
                  <h5 class="sec-title">Create a 1:1 service</h5>
                  <p>Users on topmate sell over 10k services each month.</p>
                  <div className="btnDiv  mt-4">
                    <Link>
                      <Button variant="dark" className="btnRight">
                        <FontAwesomeIcon
                          icon={faPlus}
                          className="iconGap iconSmall"
                        />
                        Add New
                      </Button>
                    </Link>
                    <Link>
                      <Button variant="outline-dark"> See templates</Button>
                    </Link>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="Subscriptions">
                <div className="flexCenter bg-white">
                  <img src={service2} alt="service" />
                  <h5 class="sec-title">Create a subscription</h5>
                  <p>
                    Offer monthly subscription of your services and ensure
                    recurring earnings
                  </p>
                  <div className="btnDiv mt-4">
                    <Link>
                      <Button variant="dark" className="btnRight">
                        <FontAwesomeIcon
                          icon={faPlus}
                          className="iconGap iconSmall"
                        />
                        Add New
                      </Button>
                    </Link>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="Webinar">
                <div className="flexCenter bg-white">
                  <img src={service5} alt="service" />
                  <h5 class="sec-title">Host a webinar</h5>
                  <p>
                    Address common questions by hosting a webinar for the masses.
                  </p>
                  <div className="btnDiv  mt-4">
                    <Link>
                      <Button variant="dark" className="btnRight">
                        <FontAwesomeIcon
                          icon={faPlus}
                          className="iconGap iconSmall"
                        />
                        Add New
                      </Button>
                    </Link>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="PriorityDM">
                <div className="flexCenter bg-white">
                  <img src={service5} alt="service" />
                  <h5 class="sec-title">Answer Priority DM</h5>
                  <p>
                    Priority DM are a good way to sell your expertise where a call
                    is not required.
                  </p>
                  <div className="btnDiv  mt-4">
                    <Link>
                      <Button variant="dark" className="btnRight">
                        <FontAwesomeIcon
                          icon={faPlus}
                          className="iconGap iconSmall"
                        />
                        Add New
                      </Button>
                    </Link>
                    <Link>
                      <Button variant="outline-dark"> See templates</Button>
                    </Link>
                  </div>
                </div>
              </Tab.Pane>

              <Tab.Pane eventKey="DigitalProduct">
                <div className="flexCenter bg-white">
                  <img src={service4} alt="service" />
                  <h5 class="sec-title">Sell digital products</h5>
                  <p>Digital products are a great source of side income.</p>
                  <div className="btnDiv  mt-4">
                    <Link>
                      <Button variant="dark" className="btnRight">
                        <FontAwesomeIcon
                          icon={faPlus}
                          className="iconGap iconSmall"
                        />
                        Add New
                      </Button>
                    </Link>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="Package">
                <div className="flexCenter bg-white">
                  <img src={service3} alt="service" />
                  <h5 class="sec-title">Create a package</h5>
                  <p>Combine your services to sell as a package</p>
                  <div className="btnDiv  mt-4">
                    <Link>
                      <Button variant="dark" className="btnRight">
                        <FontAwesomeIcon
                          icon={faPlus}
                          className="iconGap iconSmall"
                        />
                        Add New
                      </Button>
                    </Link>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
          <div className="btnRight checklist-btn">
            <Button variant="info" className="advance-checklist-btn">
              My bookings <Badge bg="danger">9</Badge>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
