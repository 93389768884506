import React, { useState, useEffect ,useContext } from 'react';
import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import axios from 'axios';
import "./Cal.css"
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';
import Tooltip from 'rc-tooltip'; // Import a tooltip component
import 'rc-tooltip/assets/bootstrap.css'; // Import tooltip styles
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  TextField,
  Snackbar,
  DialogActions
} from '@mui/material';
import { Alert } from '@mui/material';
import { Link } from 'react-router-dom';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Sidebar from "./Sidebar";
import { useHistory } from 'react-router-dom';
import { NavLink } from 'react-bootstrap';
import Navlink from "./Navlink";
import randomColor from 'randomcolor';
import {colourContext} from '../context/useecontext'
import { Rightslider } from './rightslider';




const Cal = () => {

  const [bookings, setBookings] = useState([]);
  const [selectedDateForEvent, setSelectedDateForEvent] = useState(null);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [selectedEventDetails, setSelectedEventDetails] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedEventDetails, setEditedEventDetails] = useState({});
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [deleteOptionsOpen, setDeleteOptionsOpen] = useState(false);
  const [isLoading, setLoadingg] = useState(false); 
  const [today] = useState(new Date().toISOString().split('T')[0]);
  const [loading, setLoading] = useState(false); 
  const [selectedDate, setSelectedDate] = useState('');
  const [availableSlots, setAvailableSlots] = useState([]);
  const [allSlotsFilled, setAllSlotsFilled] = useState(false);
  const [updatedBookingDate, setUpdatedBookingDate] = useState('');
  const [noSlotsAvailableDialogOpen, setNoSlotsAvailableDialogOpen] = useState(false);
  const [dateChanged, setDateChanged] = useState(false); 
  const [selectedOption, setSelectedOption] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const {selectedcolour} = useContext(colourContext)
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setusername] = useState('');
  const navigate = useNavigate();
  const [editSnackbarOpen, setEditSnackbarOpen] = useState(false);

  

  useEffect(() => {
    Axios.get('https://api.functionparks.com/home', { withCredentials: true })
      .then((response) => {
        if (response.data.loggedIn) {
          setIsLoggedIn(true);
          setusername(response.data.user.username)
        } else {
          navigate('/');
        }
      })
      .catch((error) => {
        console.error('Error checking authentication:', error);
        navigate('/');
      });
  }, []);



  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    // Redirect to the selected route
    window.location.href = selectedValue;
  };

 


  // Map to store colors for booking IDs
  const colorMap = new Map();
  
  const getRandomColor = (bookingId) => {
    let color;
  
    // Check if color for this booking ID exists in the map
    if (colorMap.has(bookingId)) {
      // Retrieve color from the map
      color = colorMap.get(bookingId);
    } else {
      // Generate a new random color
      color = randomColor();
      
      // Store the color in the map for this booking ID
      colorMap.set(bookingId, color);
    }
  
    return color;
  };
  

  useEffect(() => {
   if (username){
    axios
    .get('https://api.functionparks.com/api/getAllBookedSlots', { params: { username: username } })
    .then((response) => {
      const events = response.data.map((booking) => {
        const color = getRandomColor(booking.booking_id);
        return {
          id: booking.id.toString(),
          title: booking.name,
          start: booking.date,
          allDay: false,
          color: color,
          textColor: 'white',
          extendedProps: {
            email: booking.email,
            PhoneNumber: booking.phone_number,
            timeslot: booking.timeslot,
            purposeOfEvent: booking.purposeOfEvent,
            eventDetails: booking.eventDetails,
            booking_id: booking.booking_id,
          },
        };
      });
      
      setBookings([...events]);
    })
    .catch((error) => {
      console.error('Error fetching booked slots:', error);
    });
   }
  }, [username]);
  
  

  const handleEventClick = (selected) => {
    const { id, title, start, extendedProps } = selected.event;

      // Check if the event date is before today's date
  
    setSelectedEventDetails({
      id,
      title,
      email: extendedProps?.email || '',
      PhoneNumber: extendedProps?.PhoneNumber || '',
      bookingDate: new Date(start).toLocaleDateString(),
      bookingTime: extendedProps?.timeslot || '',
      booking_id: extendedProps?.booking_id || '',
      purposeOfEvent: extendedProps?.purposeOfEvent || '',
      eventDetails: extendedProps?.eventDetails || '',
    });

    setEditedEventDetails({
      id,
      title,
      email: extendedProps?.email || '',
      PhoneNumber: extendedProps?.PhoneNumber || '',
      bookingTime: availableTimeSlots.includes(extendedProps?.timeslot)
        ? extendedProps?.timeslot
        : '',
    });

    if (selectedDateForEvent) {
      setDialogOpen(true);
    } else {
      const incrementedDate = new Date(start);
      incrementedDate.setDate(incrementedDate.getDate() + 1);

      axios
        .get(
          `https://api.functionparks.com/slots?date=${incrementedDate.toISOString().split('T')[0]}`, {
            params: { username: username } // Send username as a query parameter
          }
        )
        .then((response) => {
          setAvailableTimeSlots(response.data);
          setDialogOpen(true);
        })
        .catch((error) => {
          console.error('Error fetching available time slots:', error);
        });
    }
  };

  const handleClose = () => {
    setDialogOpen(false);
    setSelectedEventDetails(null);
    setEditedEventDetails({});
    setIsEditing(false);
  };

  const handleEditClick = () => {
  
    // Extract the day, month, and year from the booking date
    const [day, month, year] = selectedEventDetails.bookingDate.split('/');
    const eventDate = new Date(`${year}-${month}-${day}`); // Create a Date object using YYYY-MM-DD format
  
    if (
      selectedEventDetails &&
      eventDate < new Date(new Date().setHours(0, 0, 0, 0))
    ) {

      setEditSnackbarOpen(true);

     
      return;
    }
  
    setIsEditing(true);
  };
  
  

  const handleSave = () => {

    
    // Check if any required field is empty
    if (!editedEventDetails.title || !editedEventDetails.email || !editedEventDetails.PhoneNumber || !editedEventDetails.bookingTime || !selectedDate) {
      alert('Please fill in all required fields.');
      return;
    }
  
    // Prepare the payload
    const payload = {
      name: editedEventDetails.title,
      email: editedEventDetails.email,
      phone_number: editedEventDetails.PhoneNumber,
      timeslot: editedEventDetails.bookingTime,
      date: selectedDate
    };
  
    // Include the updated date in the payload only if it's changed
    setUpdatedBookingDate(selectedDateForEvent);
    axios
      .put(`https://api.functionparks.com/api/updateEvent/${selectedEventDetails.id}`, payload)
      .then((response) => {
        setBookings((prevBookings) =>
          prevBookings.map((event) =>
            event.id === selectedEventDetails.id ? { ...event, ...editedEventDetails } : event
          )
        );
        setDialogOpen(false);
        setSnackbarOpen(true);
        window.location.reload();
      })
      .catch((error) => {
        console.error('Error updating event:', error);
      });
  
    setIsEditing(false);
  };
  
  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedEventDetails(selectedEventDetails);
  };

  const handleDateClick = (arg) => {
    setSelectedDateForEvent(arg.date.toISOString().split('T')[0]);
    setDateChanged(true);
  };

  const handleDelete = () => {
    setDeleteOptionsOpen(true);
  };

  const handleDeleteOptionClick = (option) => {
    if (option === 'selected') {
      deleteSelectedEvent();
    } else if (option === 'all') {
      deleteAllEvents();
    }
    setDeleteOptionsOpen(false);
  };

  const deleteSelectedEvent = () => {
    if (window.confirm('Are you sure you want to delete this event?')) {
      setLoadingg(true); // Start loading
      axios
        .delete(`https://api.functionparks.com/api/deleteEvent/${selectedEventDetails.id}`)
        .then((response) => {
          console.log('Event deleted successfully');
          setBookings((prevBookings) =>
            prevBookings.filter((event) => event.id !== selectedEventDetails.id)
          );
          handleClose();
        })
        .catch((error) => {
          console.error('Error deleting event:', error);
        })
        .finally(() => {
          setLoadingg(false); // Stop loading
        });
    }
  };

  const deleteAllEvents = () => {
    if (window.confirm('Are you sure you want to delete all events for this booking?')) {
      setLoadingg(true); // Start loading
      axios
        .delete(`https://api.functionparks.com/api/deleteAllEvents/${selectedEventDetails.booking_id}`)
        .then((response) => {
          console.log('All events deleted successfully');
          setBookings((prevBookings) =>
            prevBookings.filter((event) => event.extendedProps.booking_id !== selectedEventDetails.booking_id)
          );
          handleClose();
        })
        .catch((error) => {
          console.error('Error deleting events:', error);
        })
        .finally(() => {
          setLoadingg(false); // Stop loading
        });
    }
  };



  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    // Fetch available slots for the selected date
    if (selectedDate) {
      setLoading(true);
      axios
        .get(`https://api.functionparks.com/slots?date=${selectedDate}`, {
          params: { username: username } // Send username as a query parameter
        })
        .then((response) => {
          const slots = response.data;
          setAvailableSlots(slots);
          setAllSlotsFilled(slots.length === 0);
          // Reset selectAll when date changes
          // Show "No Slots Available" dialog if all slots are filled
          if (slots.length === 0) {
            setNoSlotsAvailableDialogOpen(true);
          }
        })
        .catch((error) => {
          console.error('Error fetching available slots', error);
        }).finally(() => {
          setLoading(false); // Set loading to false when fetch is complete
        });
    }
  }, [selectedDate]);

  

  return (
    <div style={{display:"flex"}}>
      <div>
      
    <Sidebar/>
      </div>
         <div style={{width:"100%", '--table-header-bg-color': selectedcolour}} >
         <Navlink/>
          <Rightslider/>
         <div>
         
          
            <select value={selectedOption} onChange={handleChange} style={{marginLeft:"0px",height:"30px",marginTop:"20px",backgroundColor: selectedcolour,color:"white",borderRadius:"5px"}}>
              <option style={{backgroundColor:selectedcolour}}  disabled value="">List View</option>
              <option style={{backgroundColor:selectedcolour}} value="/upcomingslots">Upcoming Slots</option>
              <option style={{backgroundColor:selectedcolour}} value="/completedslots">Completed Slots</option>
              <option  style={{backgroundColor:selectedcolour}}value="/deletedslots">Deleted Slots</option>
            </select>

        <Box  >
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,listWeek',
          }}
          initialView="dayGridMonth"
          events={bookings}
          eventClick={handleEventClick}
          dateClick={handleDateClick}
          dayCellContent={(arg) => (
            <span
              className="datesclr"
              style={{ color: 'black', textDecoration: 'none', cursor: 'text' }}
            >
              {arg.dayNumberText}
            </span>
          )}
          dayHeaderContent={({ date }) => (
            <span style={{ color: 'white',textDecoration:"none"}}>
              {date.toLocaleDateString('en-US', { weekday: 'short' })}
            </span>
          )}
          eventContent={(arg) => (
            <Tooltip
              placement="topLeft"
              trigger={['hover']}
              overlayStyle={{ width: '200px' }} // Adjust the width as needed
              overlay={(
                <div>
                  <p>Event: {arg.event.extendedProps.purposeOfEvent}</p>
                  <p>Booking Time: {arg.event.extendedProps.timeslot}</p>
                  <p>Email: {arg.event.extendedProps.email}</p>
                </div>
              )}
            >
              <div style={{ backgroundColor: arg.event.backgroundColor, color: arg.event.textColor, padding: '5px', width: '100%', marginBottom: "15px"}}>
                {arg.event.title}
              </div>
            </Tooltip>

          )}
          eventClassNames="pointerr"
          
    />
         


        <Dialog open={isDialogOpen} onClose={handleClose}>

            <DialogTitle>Event Details</DialogTitle>
                     
          {isLoading && <p className='deletingslots'>Please wait Deleting Slots...</p>} 

          <DialogContent>
            {selectedEventDetails && (
              <div>
              <div className='d-flex '>
              <Typography>Booking ID: {selectedEventDetails.booking_id}</Typography>
                <CopyToClipboard text={selectedEventDetails.booking_id}>
                <label className="custom-container">
                <input 
                  checked={isChecked} 
                  onChange={(e) => setIsChecked(e.target.checked)} 
                  type="checkbox"
                />
                  <svg viewBox="0 0 384 512" height="1em" xmlns="http://www.w3.org/2000/svg" className="custom-clipboard"><path d="M280 64h40c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128C0 92.7 28.7 64 64 64h40 9.6C121 27.5 153.3 0 192 0s71 27.5 78.4 64H280zM64 112c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320c8.8 0 16-7.2 16-16V128c0-8.8-7.2-16-16-16H304v24c0 13.3-10.7 24-24 24H192 104c-13.3 0-24-10.7-24-24V112H64zm128-8a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"></path></svg>
                  <svg viewBox="0 0 384 512" height="1em" xmlns="http://www.w3.org/2000/svg" className="custom-clipboard-check"><path d="M192 0c-41.8 0-77.4 26.7-90.5 64H64C28.7 64 0 92.7 0 128V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H282.5C269.4 26.7 233.8 0 192 0zm0 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM305 273L177 401c-9.4 9.4-24.6 9.4-33.9 0L79 337c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L271 239c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"></path></svg>
                </label>
                  
                </CopyToClipboard>
              </div>
               
                <Typography>Purpose of Event: {selectedEventDetails.purposeOfEvent}</Typography>
                <Typography>Name: {selectedEventDetails.title}</Typography>
                <Typography>Email: {selectedEventDetails.email}</Typography>
                <Typography>Booking Date: {selectedEventDetails.bookingDate}</Typography>
                <Typography>Booking Time: {selectedEventDetails.bookingTime}</Typography>
                <Typography>Event Details: {selectedEventDetails.eventDetails}</Typography>

                {isEditing ? (
                  <div className="m-3 ">
                    <TextField
                      label="Edit Name"
                      value={editedEventDetails.title || ''}
                      onChange={(e) =>
                        setEditedEventDetails({ ...editedEventDetails, title: e.target.value })
                      }
                    />
                    <br />
                    <TextField
                      label="Edit Email"
                      value={editedEventDetails.email || ''}
                      onChange={(e) =>
                        setEditedEventDetails({ ...editedEventDetails, email: e.target.value })
                      }
                      className="mt-3"
                    />
                    <br/>
                    <div style={{width:"30vh"} }>
                      <b style={{marginBottom:"10px"}}>Book Dates</b>
                      <input 
                          type='date' 
                          onChange={(e) => setSelectedDate(e.target.value)} 
                          value={selectedDate} 
                          required 
                          min={today}  // Set min attribute to today's date
                        />

                        <div style={{marginTop:"30px"}}>
                          <b>Time Slots</b>
                          {loading && <div className="loading">Loading...</div>}
                          {allSlotsFilled ? (
                            <p>All slots are filled for the selected date.</p>
                          ) : (
                            <>
                              {selectedDate && (
                                <>
                                  {availableSlots.map((slot) => (
                                    <button
                                      key={slot}
                                      className={`btn btn-primary m-2 ${editedEventDetails.bookingTime === slot ? 'selected' : ''}`}
                                      onClick={() => setEditedEventDetails({ ...editedEventDetails, bookingTime: slot })}
                                    >
                                      {slot}
                                    </button>
                                  ))}
                                </>
                              )}
                            </>
                          )}
                        </div>
                    </div>
                    <br />
                  
                  </div>
                ) : null}

                {isEditing ? (
                  <div>
                    <Button onClick={handleSave}>Save</Button>
                    <Button onClick={handleCancelEdit}>Cancel</Button>
                  </div>
                ) : (
                  <Button onClick={handleEditClick}>Edit</Button>
                )}
                <Button onClick={handleDelete}>Delete</Button>
                <Button onClick={handleClose}>Close Event</Button>
                <br/>
                <div className='w-100 text-center'>
                <Link to='/payment'>
                  <button className='btn btn-success'>Pay Now</button>
                </Link>
                </div>
                
              </div>
            )}
          </DialogContent>
        </Dialog>

        <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
            Event updated successfully!
          </Alert>
        </Snackbar>

        <Dialog open={deleteOptionsOpen} onClose={() => setDeleteOptionsOpen(false)}>
          <DialogTitle>Choose Delete Option</DialogTitle>
          <DialogContent>
            <Button onClick={() => handleDeleteOptionClick('selected')}>Delete Selected Slot</Button><br/>
            <Button onClick={() => handleDeleteOptionClick('all')} style={{color:"red"}}>Delete Entire All Slots</Button>
          </DialogContent>
        </Dialog>
      </Box>

          {/* No Slots Available Dialog */}
          <Dialog open={noSlotsAvailableDialogOpen} onClose={() => setNoSlotsAvailableDialogOpen(false)}>
            <DialogTitle>No Slots Available</DialogTitle>
            <DialogContent>
              <p>All slots are filled for the selected date. Please choose another date.</p>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setNoSlotsAvailableDialogOpen(false)}>OK</Button>
            </DialogActions>
          </Dialog>
          </div>
         </div>

         <Snackbar
         anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={editSnackbarOpen} autoHideDuration={3000}
           onClose={() => setEditSnackbarOpen(false)}>
          <Alert onClose={() => setEditSnackbarOpen(false)} severity="error" sx={{ width: '100%' }}>
            Editing past events is not allowed
          </Alert>
        </Snackbar>
    </div>
  );
};
 
export default Cal;


