import { createContext,  useState,useEffect } from "react";

export const colourContext = createContext(null);




export const ColourContextProvider = (props) => {
  
  const [selectedcolour, setselectedcolour] = useState("421F22");
  const [functionhallname, setfunctionhallname ] = useState("");
 
  const colourselect = (data) => {
    setselectedcolour(data);
  };
  
  const functionhall = (data) => {
    setfunctionhallname(data);
  };


  
  const contextValue = {
    colourselect,
    functionhall,
    selectedcolour,
    functionhallname
  };

  return (
    <colourContext.Provider value={contextValue}>
      {props.children}
    </colourContext.Provider>
  );
};
